import { _LOG } from "../logger";
import { Handles } from "./handles";

export class RenderShaders
{
    constructor(streamRender) {
        this._st = streamRender;
        
        this._current_program = null;
        this._handles = new Handles("shaders");

        this.createProgram = this.createProgram.bind(this);
        this.shaderSource = this.shaderSource.bind(this);
        this.attachShader = this.attachShader.bind(this);
        this.useProgram = this.useProgram.bind(this);
        this.deleteProgram = this.deleteProgram.bind(this);
        this.bindAttribLocation = this.bindAttribLocation.bind(this);
    }

    uniform(location, uniform_data) {
        if(this._current_program == null) {
            return ;
        }

        return this._current_program.uniform_data[location.pos_id] = uniform_data;
    }

    isDebug() {
       /* if(this._current_program == null) {
            return false;
        }

        return this._current_program.program_id == 9;*/
        return false;
    }

    functionReturn(cmd, ret, func_ret) {
        if( cmd['mapping']['ret_handle'] == true ) {
            this._handles.addHandle(ret, func_ret);
        }
    }

    resolveHandles(cmd, args) {
        if(('handle' in cmd['mapping']) && (cmd['mapping']['handle'].length > 0)) {
            args = this._handles.resolveHandles(args, cmd['mapping']['handle']);
        }
        return args;
    }

    getMapping() {
        return {
            'glCreateShader': {
                'func': this._st._gl.createShader,
                'ret_handle': true
            },
            'customShaderSource': {
                'func': this.shaderSource,
                'handle': [0],
                'pass': [0, 2]
            },
            'glCompileShader': {
                'func': this._st._gl.compileShader,
                'handle': [0]
            },
            'glCreateProgram': {
                'func': this.createProgram,
                'ret_handle': true,
                'pass_ret': true
            },
            'glAttachShader': {
                'func': this.attachShader,
                'handle': [0, 1]
            },
            'glDetachShader': {
                'func': this._st._gl.detachShader,
                'handle': [0, 1]
            },
            'glLinkProgram': {
                'func': this._st._gl.linkProgram,
                'handle': [0]
            },
            'glUseProgram': {
                'func': this.useProgram,
                'handle': [0]
            },
            'glDeleteShader': {
                'func': this._st._gl.deleteShader,
                'handle': [0]
            },
            'glDeleteProgram': {
                'func': this.deleteProgram,
                'handle': [0]
            },
            'glBindAttribLocation': {
                'func': this.bindAttribLocation,
                'handle': [0]
            },
        };
    }

    bindAttribLocation(program, index, name) {
        this._st._gl.bindAttribLocation(program, index, name);
    }

    async shaderSource(handle, source) {
        //source = source.replace("#version 320 es", "#version 300 es");
        //source = source.replace("objectUniforms.myPMVMatrix * ", "");
        //source = source.replace("= tx;", "= vec4(tx.x, tx.y, tx.z, 0.5);");
        //source = source.replace("fragColor = tx;", "vec4 tmp = texture(s_texture, v_texCoord); fragColor = vec4(tmp.x, tmp.y, tmp.z, tmp);");
        //source = source.replace("fragColor = tx;", "vec4 tmp = texture(s_texture, v_texCoord); fragColor = tmp;");
        //source = source.replace("= tx;", "= vec4(v_texCoord.x, v_texCoord.y, 0.0, 0.5);");

        // only treasure?
     //   source = "precision mediump float;\n\n" + source;
      //  source = source.replace("highp", "mediump");

        //source = source.replace("uniform mediump sampler2D", "uniform sampler2D");
        //source = source.replace("= tx * ", "= vec4(tx.x, tx.y, tx.z, 0.5) * ");
        
        handle.source = source;
    
        this._st._gl.shaderSource(handle, source);
    }

    createProgram(program_id) {
        var ret = this._st._gl.createProgram();
        ret.program_id = program_id;
        ret.uniform_data = {};
        ret.attribs = [];
        ret.recreated = this._st._debug;

        return ret;
    }

    attachShader(handle, shader_handle) {
        if(!('shaders' in handle)) {
            handle.shaders = [];
        }

        // check if shader_handle.handle_id is already in handle.shaders
        var found = false;
        for(var i=0; i<handle.shaders.length; i++) {
            if(handle.shaders[i].handle_id == shader_handle.handle_id) {
                found = true;
                break;
            }
        }

        if(!found) {
            handle.shaders.push(shader_handle);
        }
    
        this._st._gl.attachShader(handle, shader_handle);
    }

    useProgram(program) {
        this._current_program = program;

        this._st._gl.useProgram(program);
    }

    deleteProgram(program) {
        /*for(var i=0; i<200; i++) {
            var pos_idx = i + program.program_id * 1000;
            if(pos_idx in positions) {
                delete positions[pos_idx];
            }
        }
    
        mylogi("Deleted program", program.program_id);
    */
        this._st._gl.deleteProgram(program);
    }
}