import { _LOG } from "../logger";
import { Handles } from "./handles";

export class RenderWebGL2
{
    constructor(streamRender) {
        this._st = streamRender;

        // handles
        this._handles = new Handles("sync");
        this._vertex_handles = new Handles("vertex_array");
        this._uniform_handles = new Handles("uniform_block");

        this.drawBuffers = this.drawBuffers.bind(this);
        this.genVertexArrays = this.genVertexArrays.bind(this);
        this.texSubImage3D = this.texSubImage3D.bind(this);
        this.invalidateFramebuffer = this.invalidateFramebuffer.bind(this);
        this.readBuffer = this.readBuffer.bind(this);
        this.blitFramebuffer = this.blitFramebuffer.bind(this);

        this.customProgramBinary = this.customProgramBinary.bind(this);
        this.customGetProgramBinary = this.customGetProgramBinary.bind(this);

        this.texStorage2D = this.texStorage2D.bind(this);
        this.texStorage3D = this.texStorage3D.bind(this);
        this.clientWaitSync = this.clientWaitSync.bind(this);

        this.getUniformBlockIndex = this.getUniformBlockIndex.bind(this);
        this.uniformBlockBinding = this.uniformBlockBinding.bind(this);
        this.bindBufferRange = this.bindBufferRange.bind(this);
        this.bindBufferBase = this.bindBufferBase.bind(this);

        this._program_binaries = {};
    }

    functionReturn(cmd, ret, func_ret) {
        if( cmd['mapping']['ret_sync'] == true ) {
            this._handles.addHandle(ret, func_ret);
        }
        else if( cmd['mapping']['ret_uniform'] == true ) {
            this._uniform_handles.addHandle(ret, {'idx': func_ret});
        }
    }

    resolveHandles(cmd, args) {
        if(('sync_handle' in cmd['mapping']) && (cmd['mapping']['sync_handle'].length > 0)) {
            args = this._handles.resolveHandles(args, cmd['mapping']['sync_handle']);
        }
        if(('vertex_array' in cmd['mapping']) && (cmd['mapping']['vertex_array'].length > 0)) {
            args = this._vertex_handles.resolveHandles(args, cmd['mapping']['vertex_array']);
        }
        return args;
    }

    drawBuffers(amount, resulting_ids) {
        var decoded_ids = new DataView(resulting_ids.slice(0).buffer);
        var draw_buffs = [];

        for(var i=0; i<amount; i++) {
            draw_buffs.push(decoded_ids.getUint32(i*4, true));
        }

        //console.log('FBO: drawBuffers', draw_buffs);
        this._st._gl.drawBuffers(draw_buffs);
    }

    invalidateFramebuffer(target, amount, resulting_ids) {
        var decoded_ids = new DataView(resulting_ids.slice(0).buffer);
        var attachments = [];

        for(var i=0; i<amount; i++) {
            attachments.push(decoded_ids.getUint32(i*4, true));
        }

        //console.log('FBO: invalidateFramebuffer', target, attachments);

        this._st._gl.invalidateFramebuffer(target, attachments);
    }

    readBuffer(mode) {
        //console.log('FBO: readBuffer', mode);
        this._st._gl.readBuffer(mode);
    }

    blitFramebuffer(srcX0, srcY0, srcX1, srcY1, dstX0, dstY0, dstX1, dstY1, mask, filter) {
        //console.log('FBO: blitFramebuffer', srcX0, srcY0, srcX1, srcY1, dstX0, dstY0, dstX1, dstY1, mask, filter);

        this._st._gl.blitFramebuffer(srcX0, srcY0, srcX1, srcY1, dstX0, dstY0, dstX1, dstY1, mask, filter);
    }

    getMapping() {
        return {
            // sync stuff
            'glFenceSync': {
                'func': this._st._gl.fenceSync,
                'ret_sync': true
            },
            'glClientWaitSync': {
                'func': this.clientWaitSync,
                'sync_handle': [0]
            },
            'glDeleteSync': {
                'func': this._st._gl.deleteSync,
                'sync_handle': [0]
            },

            // texture stuff
            'glTexStorage2D': {
                'func': this.texStorage2D,
            },
            'glTexStorage3D': {
                'func': this.texStorage3D,
            },

            // draw buffer
            'glDrawBuffers': {
                'func': this.drawBuffers,
            },
            'glReadBuffer': {
                'func': this.readBuffer,
            },
            'glBlitFramebuffer': {
                'func': this.blitFramebuffer,
            },
            'glInvalidateFramebuffer': {
                'func': this.invalidateFramebuffer,
            },

            // vertex array
            'glGenVertexArrays': {
                'func': this.genVertexArrays
            },
            'glBindVertexArray': {
                'func': this._st._gl.bindVertexArray,
                'vertex_array': [0]
            },
            'glBindBufferBase': {
                'func': this.bindBufferBase,
                'vertex_array': [2]
            },

            // 3d texture data
            'glTexSubImage3D': {
                'func': this.texSubImage3D
            },

            // program binary
            'customProgramBinary': {
                'func': this.customProgramBinary,
                'handle': [0]
            },
            'customGetProgramBinary': {
                'func': this.customGetProgramBinary,
                'handle': [0]
            },

            // uniform blocks
            'glGetUniformBlockIndex': {
                'func': this.getUniformBlockIndex,
                'ret_uniform': true,
                'handle': [0]
            },
            'glUniformBlockBinding': {
                'func': this.uniformBlockBinding,
                'handle': [0]
            },
            'glBindBufferRange': {
                'func': this.bindBufferRange
            }/*,
            'glCopyTexSubImage2D': {
                'func': this._st._gl.copyTexSubImage2D
            }*/
        };
    }

    clientWaitSync(sync, flags, timeout) {
        var ret = this._st._gl.clientWaitSync(sync, this._st._gl.SYNC_FLUSH_COMMANDS_BIT, 0);
        return ret;
    }

    texStorage3D(target, levels, internalformat, width, height, depth) {
        //console.log('!!!! FBO: texStorage3D', target, levels, internalformat, width, height, depth);

        this._st._gl.texStorage3D(target, levels, internalformat, width, height, depth);
    }

    texStorage2D(target, levels, internalformat, width, height) {
        //console.log('!!!! FBO: texStorage2D', target, levels, internalformat, width, height);

        if(internalformat == 0x9278 || internalformat == 0x9274
            || internalformat == 0x93b4 || internalformat == 0x93b7
            || internalformat == 0x93bb || internalformat == 0x93bd
            || internalformat == 0x9270 || internalformat == 0x93b0
            || internalformat == 0x93b2) {
            internalformat = this._st._gl.RGBA8;
        }

        if(internalformat != 0x88F0) {
            if( internalformat == 0x8C3A) {
                internalformat = this._st._gl.RGBA8;
            }else{
                internalformat = this._st._gl.RGBA8;
            }
        }
        
        this._st._gl.texStorage2D(target, levels, internalformat, width, height);
    }

    genVertexArrays(amount, resulting_ids) {
        var decoded_ids = new DataView(resulting_ids.slice(0).buffer);

        for(var i=0; i<amount; i++) {
            var vertex_array = this._st._gl.createVertexArray();
            this._vertex_handles.addHandle(decoded_ids.getUint32(i*4, true), vertex_array);
        }
    }

    texSubImage3D(target, level, xoffset, yoffset, zoffset, width, height, depth, format, type, data) {
        if(data.byteLength > 0) {
            this._st._gl.texSubImage3D(target, level, xoffset, yoffset, zoffset, width, height, depth, format, type, data);
        }
    }

    customProgramBinary(program, hash) {
        _LOG(this, "Creating program from binary");

        var sourcePrg = this._program_binaries[hash];

        var shader_vert = this._st._gl.createShader(this._st._gl.VERTEX_SHADER);
        var shader_frag = this._st._gl.createShader(this._st._gl.FRAGMENT_SHADER);

        this._st._gl.shaderSource(shader_vert, sourcePrg.shaders[0].source);
        this._st._gl.shaderSource(shader_frag, sourcePrg.shaders[1].source);

        this._st._gl.compileShader(shader_vert);
        this._st._gl.compileShader(shader_frag);

        this._st._gl.attachShader(program, shader_vert);
        this._st._gl.attachShader(program, shader_frag);
        this._st._gl.linkProgram(program);

        if (!this._st._gl.getProgramParameter(program, this._st._gl.LINK_STATUS)) {
            const info = this._st._gl.getProgramInfoLog(program);
            console.log("Could not link program", info, program);
            //throw `Could not compile WebGL program. \n\n`;
            program.linked = false;
        }else{
            program.linked = true;

            this._st._m_shaders.useProgram(program);

            const numUniforms = this._st._gl.getProgramParameter(program, this._st._gl.ACTIVE_UNIFORMS);
            var unis = [];

            for (let index = 0; index < numUniforms; index++) {
                var uniform_info = this._st._gl.getActiveUniform(program, index);
                unis.push({'idx': index, 'name': uniform_info.name});
            }
            
            _LOG(this, "Link " + program.program_id + " ok. Found uniforms: " + numUniforms, unis);
        }
    }

    customGetProgramBinary(program, hash) {
        this._program_binaries[hash] = program;
    }

    getUniformBlockIndex(program, uniformBlockName) {
        var ret = this._st._gl.getUniformBlockIndex(program, uniformBlockName);
        return ret;
    }

    uniformBlockBinding(program, uniformBlockIndex, uniformBlockBinding) {
        uniformBlockIndex = this._uniform_handles.getHandle(uniformBlockIndex)['idx'];

        this._st._gl.uniformBlockBinding(program, uniformBlockIndex, uniformBlockBinding);
    }

    bindBufferRange(target, index, buffer, offset, size) {
        if(index == 46) {
            return; // tmp
        }
        var buffer = this._st._m_vertexes._handles.getHandle(buffer);

        this._st._gl.bindBufferRange(target, index, buffer, Number(offset), Number(size));
    }

    bindBufferBase(target, index, buffer) {
        if(index == 46) {
            return; // tmp
        }

        this._st._gl.bindBufferBase(target, index, buffer);
    }
}