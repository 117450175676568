import { _LOG } from "../logger";
import { PositionHandles } from "./handles";

export class RenderUniforms
{
    constructor(streamRender) {
        this._st = streamRender;

        this._handles = new PositionHandles("uniforms", this._st._m_shaders);

        this.getUniformLocation = this.getUniformLocation.bind(this);
        this.customCachedUniform = this.customCachedUniform.bind(this);
        
        this.uniformMatrix4fv = this.uniformMatrix4fv.bind(this);
        this.uniform4fv = this.uniform4fv.bind(this);
        this.uniform3fv = this.uniform3fv.bind(this);
        this.uniform2fv = this.uniform2fv.bind(this);
        this.uniform1fv = this.uniform1fv.bind(this);

        this.uniform1iv = this.uniform1iv.bind(this);
        this.uniformMatrix3fv = this.uniformMatrix3fv.bind(this);
        
        this.uniform1i = this.uniform1i.bind(this);
        this.uniform4f = this.uniform4f.bind(this);

        this._uniform_cache = {};
    }

    functionReturn(cmd, ret, func_ret) {
        if( cmd['mapping']['ret_position'] == true ) {
            this._handles.addHandle(ret, func_ret);
        }
    }

    resolveHandles(cmd, args) {
        if(('position' in cmd['mapping']) && (cmd['mapping']['position'].length > 0)) {
            args = this._handles.resolveHandles(args, cmd['mapping']['position']);
        }
        return args;
    }

    getMapping() {
        return {
            'glGetUniformLocation': {
                'func': this.getUniformLocation,
                'handle': [0],
                'pass_ret': true
            },
            'customCachedUniform': {
                'func': this.customCachedUniform,
            },

            // vectors
            'customUniformMatrix4fv': {
                'func': this.uniformMatrix4fv,
                'pass': [0, 2, 3, 4, 5],
                'position': [0]
            },
            'customUniform4fv': {
                'func': this.uniform4fv,
                'pass': [0, 2, 3, 4],
                'position': [0]
            },
            'customUniform3fv': {
                'func': this.uniform3fv,
                'pass': [0, 2, 3, 4],
                'position': [0]
            },
            'customUniform2fv': {
                'func': this.uniform2fv,
                'pass': [0, 2, 3, 4],
                'position': [0]
            },
            'customUniform1fv': {
                'func': this.uniform1fv,
                'pass': [0, 2, 3, 4],
                'position': [0]
            },
            
            'glUniform1iv': {
                'func': this.uniform1iv,
                'pass': [0, 2],
                'position': [0]
            },

            'glUniformMatrix3fv': {
                'func': this.uniformMatrix3fv,
                'pass': [0, 2, 3],
                'position': [0]
            },

            // values
            'glUniform1i': {
                'func': this.uniform1i,
                'pass': [0, 1],
                'position': [0]
            },
            'glUniform4f': {
                'func': this.uniform4f,
                'pass': [0, 1, 2, 3, 4],
                'position': [0]
            },
        };
    }

    uniform1i(location, val) {
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, val);
        }
        this._st._gl.uniform1i(location, val);
    }

    uniform4f(location, v0, v1, v2, v3) {
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, [v0, v1, v2, v3]);
        }
        this._st._gl.uniform4f(location, v0, v1, v2, v3);
    }

    addToUniformCache(ptr, param) {
        this._uniform_cache[ptr] = param;

        this._st._stats.addUniformCache(param.byteLength);
    }

    resized() {
        
    }

    getUniformLocation(program, name, pos_id) {
        if(name == "ObjectUniformsBlock.myPMVMatrix") {
            name = "objectUniforms.myPMVMatrix";
        }
        var pos = this._st._gl.getUniformLocation(program, name);
        var pos_idx = pos_id + program.program_id * 1000;

        if(pos == null) {
            // no such uniform
            mylogi("Can't find uniform", program, name, pos_id, pos_idx, pos);
            return;
        }

        pos.program = program;
        pos.program_id = program.program_id;
        pos.pos_id = pos_id;
        this._handles.addHandle(pos_idx, pos);
    
        if(!('positions' in program)) {
            program['positions'] = [];
        }

        // check that name is unique
        var found = false;
        for(var i = 0; i < program['positions'].length; i++) {
            if(program['positions'][i]['name'] == name) {
                found = true;
                break;
            }
        }

        if(!found) {
            program['positions'].push({'id': pos_id, 'name': name, pos});
        }

        if( name.indexOf("u_scale_tex") != -1 ) {
            pos.is_scale = true;
        }else{
            pos.is_scale = false;
        }
    }

    uniform1iv(location, value) {
        this._st._gl.uniform1i(location, new Int32Array(value.slice(0).buffer)[0]);

        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, new Int32Array(value.slice(0).buffer)[0]);
        }
    }
    
    uniform4fv(location, cache, ptr, value) {
        var param = new Float32Array(value.slice(0).buffer);

        if(cache == 1) {
            if(ptr in this._uniform_cache) {
                param = this._uniform_cache[ptr];
            }else{
                _LOG(this, "No cached uniform");
            }
        }else{
            if(cache == 3) {
                this.addToUniformCache(ptr, param);
            }
        }
        this._st._gl.uniform4fv(location, param);
        
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, param);
        }
    }

    uniform3fv(location, cache, ptr, value) {
        var param = new Float32Array(value.slice(0).buffer);

        if(cache == 1) {
            if(ptr in this._uniform_cache) {
                param = this._uniform_cache[ptr];
            }else{
                _LOG(this, "No cached uniform");
            }
        }else{
            if(cache == 3) {
                this.addToUniformCache(ptr, param);
            }
        }
        
        this._st._gl.uniform3fv(location, param);
        
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, param);
        }
    }
    
    uniform2fv(location, cache, ptr, value) {
        var param = new Float32Array(value.slice(0).buffer);

        if(cache == 1) {
            if(ptr in this._uniform_cache) {
                param = this._uniform_cache[ptr];
            }else{
                _LOG(this, "No cached uniform");
            }
        }else{
            if(cache == 3) {
                this.addToUniformCache(ptr, param);
            }
        }
        
        this._st._gl.uniform2fv(location, param);
        
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, param);
        }
    }

    uniform1fv(location, cache, ptr, value) {
        var param = new Float32Array(value.slice(0).buffer);

        if(cache == 1) {
            if(ptr in this._uniform_cache) {
                param = this._uniform_cache[ptr];
            }else{
                _LOG(this, "No cached uniform");
            }
        }else{
            if(cache == 3) {
                this.addToUniformCache(ptr, param);
            }
        }
        
        this._st._gl.uniform1fv(location, param);
        
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, param);
        }
    }
    
    uniformMatrix4fv(location, transpose, cache, ptr, value) {
        var param = new Float32Array(value.slice(0).buffer);

        if(cache == 1) {
            if(ptr in this._uniform_cache) {
                param = this._uniform_cache[ptr];
            }else{
                _LOG(this, "No cached uniform");
            }
        }else{
            if(cache == 3) {
                this.addToUniformCache(ptr, param);
            }
        }

        this._st._gl.uniformMatrix4fv(location, transpose, param);
        
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, param);
        }
    }
    
    uniformMatrix3fv(location, transpose, value) {
        this._st._gl.uniformMatrix3fv(location, transpose, param);
        
        if(this._st._m_shaders.isDebug()) {
            this._st._m_shaders.uniform(location, param);
        }
    }

    customCachedUniform(cache_id) {
        if(cache_id in this._uniform_cache) {
            mylogi(this._uniform_cache[cache_id]);
            this._uniform_cache[cache_id]['func'].apply(this._st._gl, this._uniform_cache[cache_id]['args']);
        }else{
            _LOG(this, "Can't find cached uniform");
        }
    }
}