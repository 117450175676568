'use strict';

export class PayStripe {
    constructor(payments) {
        this._payments = payments;
        this._currentPayObj = null;
        this._currentClientSecret = null;
        this._elements = null;

        this._stripe = Stripe("pk_test_51EAiktBEaidOzrZmREXHQxQAD1jHeOXWgXKRijDq2poLuErrHrVs3Mzs2W93F3WZPLzqXIX3xxcwhyjRRShxtBqa00ZpUCXL3h");  

        this.handleSubmit = this.handleSubmit.bind(this);
        this.closepay = this.closepay.bind(this);
        this.resizePayment = this.resizePayment.bind(this);

        document.querySelector("#payment-form").addEventListener("submit", this.handleSubmit);
        
        var payclose = document.getElementById("close-pay");
        payclose.onclick = this.closepay;

        var dev = parser.getDevice().type;
        if(dev == "mobile" || dev =="tablet") {
            $( window ).resize(() => {
                this.resizePayment();
            });
            this.resizePayment();
        }else{
            $("#submit2").css("display", "none");
        }
    }

    async pay_now(pay_obj)
    {
        this.setLoading(true);
        
        try{
            this._currentPayObj = pay_obj;

            mylogd("Payment request url: " + serverurl_pay+"api/pay/"+window.profile.getProfileID()+"/"+pay_obj['game_id']+"/");
    
            const response = await fetch(serverurl_pay+"api/pay/"+window.profile.getProfileID()+"/"+pay_obj['game_id']+"/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(pay_obj),
            });
            const res_json = await response.json();

            mylogd("Payment intent: " + JSON.stringify(res_json));

            const clientSecret = res_json['clientSecret'];
            const amount = res_json['amount'];
            const desc = res_json['desc'];
            this._last_desc = desc;
    
            var cashback_amount = 0;
            var cashback_prc = 0;
            if(res_json.hasOwnProperty('cashback')) {
                cashback_amount = res_json['cashback']['amount'];
                cashback_prc = res_json['cashback']['prc'];
            }
    
            this._currentClientSecret = clientSecret;
    
            const appearance = {
                theme: 'stripe'
            };
            this._elements = this._stripe.elements({ appearance, clientSecret });
    
            const paymentElement = this._elements.create("payment");
            paymentElement.mount("#payment-element");

            var thisStripe = this;
            paymentElement.on('change', function(event) {
                thisStripe.setLoading(false);
            });
            paymentElement.on('blur', function(event) {
                setTimeout(function() {
                    window.scrollTo(0, 0);
                }, 250);
            });
            paymentElement.on('focus', function(event) {
                let scrollAttempt = setInterval(() => {
                    if(window.scrollX == 0 && window.scrollY == 0 && thisStripe._payments.isPaying()) {
                        clearInterval(scrollAttempt);
                    }
                    window.scrollTo(0, 0);
                }, 1000);
            });
            
            var modal = document.getElementById("popup_purchase");
            modal.style.display = "block";
    
            window.Main.makescreenshot(false);
            $("#main-image").css({filter: 'blur(5px)'});
            $("#main-image").show();
            $("#main-image").css({"z-index": 100});
    
            $("#popup_purchase").css({opacity: 0});
            $("#popup_purchase").animate({opacity: 1}, 250, function(){
            });
    
            if(res_json['used_tokens'] > 0 && isFlag("tokens")) {
                $("#price").hide();
                $("#price-tokens").show();
    
                $("#price-old").html("<s>$" + amount / 100);
                $("#price-use-tokens").html(res_json['used_tokens']);
                $("#price2").html("$" + res_json['amount_discount'] / 100);
            }else{
                $("#price").show();
                $("#price-tokens").hide();
                $("#price").html("$" + amount / 100);
            }
            $("#item_desc").html(desc);

            if(cashback_amount > 0) {
                $("#cashback").show();
                $("#cashback-amount").html(cashback_amount);
            }else{
                $("#cashback").hide();
            }
        }catch(e) {
            this._payments.paymentError(e.toString());
    
            throw e;
        }
    }

    setLoading(isLoading) {
        if (isLoading) {
            document.querySelector("#submit").disabled = true;
            document.querySelector("#submit2").disabled = true;
            document.querySelector("#spinnerbutton").classList.remove("hidden");
            document.querySelector("#spinnerbutton2").classList.remove("hidden");
            document.querySelector("#button-text").classList.add("hidden");
            document.querySelector("#button-text2").classList.add("hidden");
    
            document.querySelector("#close-pay").classList.add("hidden");  
        } else {
            document.querySelector("#submit").disabled = false;
            document.querySelector("#submit2").disabled = false;
            document.querySelector("#spinnerbutton").classList.add("hidden");
            document.querySelector("#spinnerbutton2").classList.add("hidden");
            document.querySelector("#button-text").classList.remove("hidden");
            document.querySelector("#button-text2").classList.remove("hidden");
    
            document.querySelector("#close-pay").classList.remove("hidden");
        }
    }

    resizePayment()
    {
        if( $( window ).width() < $( window ).height() ) {
            $("#modal-payment").css("width", "350px");
            $("#modal-payment").css("height", "auto");

            $(".onlyvertical").css("display", "block");

            $("#submit").css("display", "block");
            $("#submit2").css("display", "none");

            $("#payment-element").css("width", "100%");
            //$("#payment-element").css("height", "auto");
            $("#payment-element").css("margin-top", "10px");

            //$("#payment-element").css("transform", "translate(0px, 0px)");
            $("#overpay").css({"overflow-y": "auto", "max-height": "auto", "display": "inline-block", "margin-left": "0px"});
            $("#all_pay").css("padding-top", "40px");
        }else{
            $("#all_pay").css("padding-top", "10px");
            $("#modal-payment").css("width", "600px");
            $("#modal-payment").css("height", "95%");

            $("#overpay").css({"overflow-y": "scroll", "max-height": "95%", "display": "inline-block", "margin-left": "35px"});

            $(".onlyvertical").css("display", "none");

            $("#submit").css("display", "none");
            $("#submit2").css("display", "block");

            $("#payment-element").css("width", "auto");
            $("#payment-element").css("margin-top", "0px");
            //$("#payment-element").css("height", "100%");

            //$("#payment-element").css("transform", "translate(-65px, -32px)");
        }
    }

    closepay()
    {
        var modal = document.getElementById("popup_purchase");
        modal.style.display = "none";

        if(window.Main.current_stream != null) {
            $("#main-image").hide();
            $("#main-image").css({"z-index": "auto"});
        }

        if(this._currentPayObj != null){
            this._payments.paymentCancel(this._currentPayObj);

            this._currentPayObj = null;
        }
    }

    showMessage(messageText) {
        const messageContainer = document.querySelector("#payment-message");

        messageContainer.classList.remove("hidden");
        messageContainer.textContent = messageText;
    }

    async handleSubmit(e) {
        mylogd("Payment started");
        window.Main.send_ev("purchase_start", {});
    
        e.preventDefault();
        this.setLoading(true);
        
        const { error } = await this._stripe.confirmPayment({
            elements: this._elements,
            confirmParams: {
                return_url: serverurl_pay
            },
            redirect: "if_required"
        });
    
        var continue_pay = false;
        if(error != null) {
            if ((error.type === "card_error" || error.type === "validation_error") && error.decline_code != "test_mode_live_card") {
                window.Main.send_ev("bank_error", {});
                //this.showMessage("An unexpected error occurred. You won't be charged.");
            } else {
                if(error.decline_code != "test_mode_live_card") {
                    //this.showMessage("An unexpected error occurred. You won't be charged.");
                }
                window.Main.send_ev("bank_pay", {});
            }
    
            if(error.decline_code == "test_mode_live_card") {
                continue_pay = true;

                mylogd("Payment can be continued");
            }else{
                myloge("Payment error during confirm: " + error.type + " " + error.decline_code);
            }
        }else{
            continue_pay = true;
        }
        
        if(continue_pay) {
            const { paymentIntent } = await this._stripe.retrievePaymentIntent(this._currentClientSecret);
    
            if(continue_pay) {
                paymentIntent.status = "succeeded";
            }
    
            switch (paymentIntent.status) {
                case "succeeded":
                    mylogd("Payment succeeded");

                    const billing_response = await fetch(serverurl_pay+"api/pay/stripe/"+paymentIntent.id+"/", {
                        method: "GET"
                    });
                    var billing_json = await billing_response.json();

                    mylogd("Payment validation result: " + JSON.stringify(billing_json));
        
                    if(billing_json['status'] == "ok") {
                        billing_json['purchase']['desc'] = this._last_desc;
                        window.ui.showPaymentDone(billing_json['purchase'], billing_json['bonus']);
        
                        $("#main-image").hide();
                        $("#main-image").css({"z-index": "auto"});
        
                        var modal = document.getElementById("popup_purchase");
                        modal.style.display = "none";
        
                        this._payments.paymentDone(billing_json);
                    }else{
                        myloge("Error during payment finalization: " + JSON.stringify(billing_json));
                        this.showMessage("Your payment was not successful, please try again.");
                    }
                break;
                case "processing":
                    mylogd("Payment processing");

                    this.showMessage("Your payment is processing.");
                break;
                case "requires_payment_method":
                    mylogd("Payment requires method");
                    this.showMessage("Your payment was not successful, please try again.");
                break;
                default:
                    myloge("Something went wrong");
                    this.showMessage("Something went wrong.");
                break;
            }
        }
    
        this.setLoading(false);
    }
}