import { _LOG } from "../logger";
import { Handles } from "./handles";

export function load_image(src, key) {
    return new Promise((resolve, reject) => {
      let img = new Image()
      img.onload = () => {
        resolve(img);
      }
      img.onerror = () => {
        mylogi("Error on image creation, data", src, key);

        reject();
      }
      img.src = 'data:image/webp;base64,' + src;
    });
}

function scale_image(image, width, height) {
    return new Promise((resolve, reject) => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(image, 0, 0, width, height);

        let img = new Image()
        img.onload = () => {
          //mylogi("Scaled image " + image.width + "x" + image.height + " to " + img.width +"x" + img.height);
          resolve(img);
        }
        img.onerror = () => {
          mylogi("Error on image scale");
  
          reject();
        }
        img.src = canvas.toDataURL();
      });
}

export class RenderTextures
{
    constructor(streamRender) {
        this._st = streamRender;
        this._ts = this._st._textureStreaming;

        this._handles = new Handles("textures");

        this._active_slot = -1;
        this._active_texture = -1;
        this._active_target = -1;

        this._tex_debug_all = false;
        this._tex_debug = -1;
        this._async_creation = false;

        this._tex_calls = [];
        this._tex_gpu = {};

        this.activeTexture = this.activeTexture.bind(this);
        this.generateTextures = this.generateTextures.bind(this);
        this.bindTexture = this.bindTexture.bind(this);
        this.deleteTexture = this.deleteTexture.bind(this);
        this.deleteTextures = this.deleteTextures.bind(this);
        this.customTexImage2D = this.customTexImage2D.bind(this);
        this.customSubTexture = this.customSubTexture.bind(this);
        this.texParameteri = this.texParameteri.bind(this);
    }

    functionReturn(cmd, ret, func_ret) {
        
    }

    resolveHandles(cmd, args) {
        if(('tex_handle' in cmd['mapping']) && (cmd['mapping']['tex_handle'].length > 0)) {
            args = this._handles.resolveHandles(args, cmd['mapping']['tex_handle']);
        }
        return args;
    }

    getMapping() {
        return {
            'glActiveTexture': {
                'func': this.activeTexture,
                'pass': [0],
            },
            'glTexParameteri': {
                'func': this.texParameteri
            },
            'glTexParameterf': {
                'func': this._st._gl.texParameterf
            },
            'glGenTextures': {
                'func': this.generateTextures
            },
            'glBindTexture': {
                'func': this.bindTexture
            },
            'glDeleteTexture': {
                'func': this.deleteTexture,
                'tex_handle': [0]
            },
            'glDeleteTextures': {
                'func': this.deleteTextures
            },

            'customTexture': {
                'func': this.customTexImage2D
            },
            'customSubTexture': {
                'func': this.customSubTexture
            },
            'glCompressedTexImage2D': {
            },
        };
    }

    texParameteri(target, pname, param) {
        var exclude = [0x84FE, 0x8a48, 0x8e42, 0x8e43, 0x8e44, 0x8e45];

        if(pname == 0x8A49 || pname == 0x84fe || pname == 0x8a48 ||
            pname == 0x8e42 || pname == 0x8e43 || pname== 0x8e44 || pname == 0x8e45) {
            // ignore
            return;
        }

        //if(exclude.indexOf(pname) == -1 && target != 0x9009) {
        this._st._gl.texParameteri(target, pname, param);
        //}

        // get current texture
        var handle = this._handles.getHandle(this._active_texture);
        if(handle != null) {
            if('calls' in handle) {
                handle['calls'].push("texParameteri: " + WebGLDebugUtils.glEnumToString(target) + " " + WebGLDebugUtils.glEnumToString(pname) + "=" + WebGLDebugUtils.glEnumToString(param));
            }
        }
    }

    activeTexture(slot) {
        this._active_slot = slot;
    
        this._st._gl.activeTexture(slot);
    }

    generateTextures(amount, resulting_ids) {
        var decoded_ids = new DataView(resulting_ids.slice(0).buffer);
        for(var i=0; i<amount; i++) {
            this._handles.addHandle(decoded_ids.getUint32(i*4, true), this._st._gl.createTexture());

            if(decoded_ids.getUint32(i*4, true) == 1778) {
                //mylogi("FBO: created 1778");
            }
        }
    }

    bindTexture(target, id) {
        if(target == 0x9009) {
            // ignore
            return;
        }

        this._active_texture = id;
        if(this._tex_debug_all) {
            this._tex_debug = id;
        }
        this._active_target = target;
    
        var handle = this._handles.getHandle(id);
        this._st._gl.bindTexture(target, handle);

        if(handle != null) {
            this._st._stats.textureUsage(id, handle);

            if(this._st.isPreloading() == false && '_tex_calls'+id in handle) {
                // do all pending calls
                var arr = handle['_tex_calls'+id];
                delete handle['_tex_calls'+id];

                for(var i=0; i<arr.length; i++) {
                    var call = arr[i];
                    this.glTexture("bind", id, target, call[0], call[1], call[2], call[3], call[4], call[5], call[6], call[7], call[8], call[9]);
                }
            }
        }
    }

    deleteTexture(handle, id) {
        this._st._gl.deleteTexture(handle);
    
        this._ts.textureDeleted(id);
    }

    deleteTextures(count, ids) {
        var decoded_ids = new DataView(ids.slice(0).buffer);
        for(var i=0; i<count; i++) {
            this.deleteTexture( this._handles.getHandle(decoded_ids.getUint32(i*4, true)), decoded_ids.getUint32(i*4, true) );
        }
    }

    needDebug(texId) {
        return texId == this._tex_debug || this._tex_debug_all;
    }

    makeTextures() {
        /*for(var i=0; i<this._tex_calls.length; i++) {
            this._st._gl.bindTexture(this._tex_calls[i][1], this._handles.getHandle(this._tex_calls[i][0]));

            this.glTexture("make", this._tex_calls[i][0], this._tex_calls[i][1], this._tex_calls[i][2], this._tex_calls[i][3], this._tex_calls[i][4], this._tex_calls[i][5],
                                this._tex_calls[i][6], this._tex_calls[i][7], this._tex_calls[i][8], this._tex_calls[i][9]);
        }
        this._tex_calls = [];*/
    }

    // texture helpers
    glTexture(source, texId, target, level, internalformat, border, format, type, image, xoffset, yoffset, width, height) {
        var orig_type = type;
        var orig_format = format;

        if(type == this._st._gl.UNSIGNED_SHORT_5_6_5) {
            type = this._st._gl.UNSIGNED_BYTE;
        }
        if(type == this._st._gl.UNSIGNED_SHORT_4_4_4_4) {
            type = this._st._gl.UNSIGNED_BYTE;
        }
        border = 1;

        var texture_object = this._handles.getHandle(this._active_texture);

        // maybe hack - everything is RGBA
        if(format != this._st._gl.RGB) {
            format = this._st._gl.RGBA;
            internalformat = this._st._gl.RGBA;
        }else{
            format = this._st._gl.RGB;
            internalformat = this._st._gl.RGB;
        }
        //

        if(this._st.isPreloading()) {
            // don't call gl functions, save for later use
            if(('_tex_calls'+texId) in texture_object == false) {
                texture_object['_tex_calls'+texId] = [];
            }
            texture_object['_tex_calls'+texId].push([level, internalformat, border, format, type, image, xoffset, yoffset, width, height]);

            if(this._st.isDebug()) {
                if(('calls' in texture_object) == false) {
                    texture_object['calls'] = [];
                }

                texture_object.calls.push('Delaying '+source+' call due to preload ' + xoffset + " " + yoffset + " " + width + " " + height);
            }
            return;
        }else{
            if(('_tex_calls'+texId) in texture_object) {
                var arr = texture_object['_tex_calls'+texId];
                delete texture_object['_tex_calls'+texId];

                for(var i=0; i<arr.length; i++) {
                    var call = arr[i];
                    this.glTexture("bind", id, target, call[0], call[1], call[2], call[3], call[4], call[5], call[6], call[7]);
                }
            }
        }

        if( this.needDebug(texId) ) {
            //_LOG(this, image);
            _LOG(this, xoffset == null?"texImage2D":"texSubImage2D", "texId:"+this._active_texture, texId, target, level, internalformat, border, format, type, image==null?width:image.width, image==null?height:image.height, xoffset, yoffset, image);
        }

        if(xoffset == null) {
            //if( this.needDebug(texId) && image.width == 256 ) {
            //    this._st._gl.texImage2D(target, level, internalformat, image.width, image.height, 0, format, type, new Uint8Array(image.width*image.height*4));
            //}else{
            //mylogi("texImage2D", target, level, internalformat, format, type, image);
            if(image == null) {
                this._st._gl.texImage2D(target, level, internalformat, width, height, 0, format, type, null);

                texture_object.width = width;
                texture_object.height = height;

                // only tex image2d can change size
                if(texId in this._tex_gpu) {
                    var i = this._tex_gpu[texId];
                    if(i != null && i != undefined) {
                        this._st._stats.addImageGPU(-i.width*i.height*4);
                    }
                }
                this._st._stats.addImageGPU(width*height*4);
                this._tex_gpu[texId] = image;
            }else{
                this._st._gl.texImage2D(target, level, internalformat, format, type, image);

                texture_object.width = image.width;
                texture_object.height = image.height;

                // only tex image2d can change size
                if(texId in this._tex_gpu) {
                    var i = this._tex_gpu[texId];
                    this._st._stats.addImageGPU(-i.width*i.height*4);
                }
                this._st._stats.addImageGPU(image.width*image.height*4);
                this._tex_gpu[texId] = image;
            }
            //}
        }else{
            if(image != null && 'scaleFactor' in image) {
                // first - upscale
                //texImage2D(target, level, internalformat, width, height, border, format, type, pixels)
                var width = Math.round(texture_object.width * image.scaleFactor);
                var height = Math.round(texture_object.height * image.scaleFactor);

                mylogi("Rescaling "+texture_object.key+" texture to " + width + "x" + height + " from " + texture_object.width + "x" + texture_object.height);

                this._handles.addHandle(this._active_texture, texture_object);

                this._st._gl.texImage2D(target, level, format, width, height, border, format, type, null);
                this._st._gl.texSubImage2D(target, level, xoffset * image.scaleFactor, yoffset * image.scaleFactor, format, type, image);
                
                if(this._st.isDebug()) {
                    if(('calls' in texture_object) == false) {
                        texture_object['calls'] = [];
                    }

                    texture_object.calls.push("Rescale " + texture_object.key + " to " + width + "x" + height + " from " + texture_object.width + "x" + texture_object.height);
                }
            }else{
                //this._st._gl.texImage2D(target, level, format, width + xoffset, height + yoffset, 0, format, type, null);

                if(image == null) {
                    //target, level, xoffset, yoffset, width, height, format, type, pixels
                    this._st._gl.texSubImage2D(target, level, xoffset, yoffset, width, height, format, type, new Uint8Array(width*height*4));
                }else{
                    this._st._gl.texSubImage2D(target, level, xoffset, yoffset, format, type, image);
                }
            }
        }

        //
        if(this._st.isDebug()) {
            if(('calls' in texture_object) == false) {
                texture_object['calls'] = [];
            }

            WebGLDebugUtils.init(window.render._gl);
            texture_object.calls.push((xoffset == null?"texImage2D":"texSubImage2D") + " " + source + " " + WebGLDebugUtils.glEnumToString(format) + " " + WebGLDebugUtils.glEnumToString(type) + " " + width + "x" + height + " " + (xoffset == null?"":(xoffset + " " + yoffset)) + " " + (image==null?"no img":"img ok")
                        + ". Orig:" + WebGLDebugUtils.glEnumToString(orig_format) + " / " + WebGLDebugUtils.glEnumToString(type));

            if(image != null) {
                texture_object.calls.push({"width": image.width, "height": image.height, "obj": image});
            }
        }

       // mylogi("texId::" + texId + " key: " + image.width + "_" + image.height + "_" + type + "_" + format);
    }

    async preloadNextFrame(cmd) {
        var load_was = 0;

        if(cmd['command'] == 'customSubTexture') {
            //customSubTexture(target, level, xoffset, yoffset, width, height, format, type, hash, downscale, image) {
            var args = cmd['params'];

            var image = args[10];
            var width = args[4];
            var height = args[5];

            if(image.byteLength == 0) {
                return 0;
            }

            // var key = "img_" + width + "_" + height + "_" + format + "_" + type + "_" + hash;
            var key = "img_" + width + "_" + height + "_" + args[6] + "_" + args[7] + "_" + args[8];

            var img = null;
            if( this._ts.isInCache(key) ) {
                img = this._ts.getFromCache(key);
                
                var size = this._st._preloader.getRealSize(key);
                if(size != null) {
                    img.scaleFactor = size['width'] / width;
                }
            }else{
                var size = this._st._preloader.getRealSize(key);

                if(size != null) {
                    mylogi("Used size: " + key + " " + size['width'] + "x" + size['height']);
                    img = await createImageBitmap(new Blob([image]), {premultiplyAlpha: this._st._premultiply, resizeWidth: size['width'], resizeHeight: size['height'], resizeQuality: "pixelated"});

                    img.scaleFactor = size['width'] / width;             
                }else{
                    img = await createImageBitmap(new Blob([image]), {premultiplyAlpha: this._st._premultiply, resizeWidth: width, resizeHeight: height, resizeQuality: "pixelated"});
                }

                load_was = 1;
            }

            cmd['params'].push(img);
            cmd['preloaded'] = true;
        }else{
            //customTexImage2D(target, level, internalformat, width, height, border, format, type, hash, downscale, image) {
            var args = cmd['params'];

            var image = args[10];
            var width = args[3];
            var height = args[4];

            if(image.byteLength == 0) {
                return 0;
            }

            // var key = "img_" + width + "_" + height + "_" + format + "_" + type + "_" + hash;
            var key = "img_" + width + "_" + height + "_" + args[6] + "_" + args[7] + "_" + args[8];

            var img = null;
            
            if( this._ts.isInCache(key) ) {
                img = this._ts.getFromCache(key);
            }else{
                img = await createImageBitmap(new Blob([image]), {/*remultiplyAlpha: "none"*/});
                load_was = 1;
            }

            cmd['params'].push(img);
            cmd['preloaded'] = true;
        }

        return load_was;
    }

    saveForNextLoad(texIds) {
        var keys = [];

        for(var i=0; i<texIds.length; i++) {
            var texture_object = this._handles.getHandle(texIds[i]);
            if('key' in texture_object) {
                keys.push(texture_object.key);
            }
        }

        this._st._preloader.saveForNextLoad(keys);
    }

    commonTexImage2D(target, level, internalformat, width, height, border, format, type, hash, image, xoffset, yoffset, downscale, parsed_image) {
        try{
            var key = "img_" + width + "_" + height + "_" + format + "_" + type + "_" + hash;

            if(this._active_texture == 5) {
                mylogi("!!!!!! ", key, width, height, xoffset, yoffset);
            }

            this._handles.getHandle(this._active_texture).key = key;

            if( this._ts.isInCache(key) ) {
                // create from cache, make async?
                var cache = this._ts.getFromCache(key);

                var size = this._st._preloader.getRealSize(key);
                if(size != null) {
                    cache['data'].scaleFactor = size['width'] / width;
                }

                this.glTexture("render_cache", this._active_texture, target, level, internalformat, border, format, type, cache['data'], xoffset, yoffset, width, height);

                if(cache['temp']) {
                    // add to waiting list this texture
                    this._ts.addWaitingTexture({
                        'key': key,
                        'activeSlot': this._active_slot,
                        'targetActive': this._active_target,
                        'texId': this._active_texture,
                        'target': target,
                        'level': level,
                        'internalformat': internalformat,
                        'width': width,
                        'height': height,
                        'border': border,
                        'format': format,
                        'type': type,
                        'downscale': downscale,
                        'hash': hash,
                        'xoffset': xoffset,
                        'yoffset': yoffset
                    });
    
                    this._handles.getHandle(this._active_texture).waiting = true;
                }else{
                    this._handles.getHandle(this._active_texture).waiting = false;
                }
                return;
            }

            var actual_downscale = downscale;

            // create image
            if(this._async_creation) {
                this._ts.createIncomingTexture(key, encoded, "command");
            }else{
                if(downscale > 1 && xoffset != null) {
                    // upscale thumb
                    var img = parsed_image;
                    actual_downscale = 1;

                    this.glTexture("render", this._active_texture, target, level, internalformat, border, format, type, img, xoffset, yoffset, width, height);
                    this._ts.addToCache(key, img, downscale > 1); // is this needed?
                }else{
                    var img = parsed_image;
                    this.glTexture("render", this._active_texture, target, level, internalformat, border, format, type, img, xoffset, yoffset, width, height);
                    this._ts.addToCache(key, img, downscale > 1); // is this needed?
                }

                //mylogi("Creating future image");
            }

            if(downscale > 1) {
                // this is thumb
                this._st._stats.addIncomingThumbs(image.byteLength);
            }else{
                this._st._stats.addUsedTexture(key);
            }

            // if we are expecting big version - add to waiting queue
            if(downscale > 1 || this._async_creation) {
                this._ts.addWaitingTexture({
                    'key': key,
                    'activeSlot': this._active_slot,
                    'targetActive': this._active_target,
                    'texId': this._active_texture,
                    'target': target,
                    'level': level,
                    'internalformat': internalformat,
                    'width': width,
                    'height': height,
                    'border': border,
                    'format': format,
                    'type': type,
                    'downscale': actual_downscale,
                    'hash': hash,
                    'xoffset': xoffset,
                    'yoffset': yoffset
                });

                this._handles.getHandle(this._active_texture).waiting = true;
                //this._st._preloader.waitingFor(key);
            }else{
                this._handles.getHandle(this._active_texture).waiting = false;
            }
        }catch(e) {
            _LOG(this, e);
    
            throw e;
        }
    }

    // streamed textures
    customTexImage2D(target, level, internalformat, width, height, border, format, type, hash, downscale, image, parsed_image) {
        if(width == 1911) {
            //mylogi("customTexImage2D", target, level, xoffset, yoffset, width, height, format, type, hash, downscale, image, parsed_image);
        }

        /*if(image == null || image.byteLength == 0) {
            this.commonTexImage2D(target, level, internalformat, width, height, border, format, type, hash, image, null, null, null, null);
            return; // empty call
        }*/
        this.commonTexImage2D(target, level, internalformat, width, height, border, format, type, hash, image, null, null, downscale, parsed_image);
    }

    customSubTexture(target, level, xoffset, yoffset, width, height, format, type, hash, downscale, image, parsed_image) {   
        //mylogi("customSubTexture", width, height);

        /*if(image.byteLength == 0) {
            //this.customTexImage2D(target, level, format, width, height, 0, format, type, hash, downscale, null, null);
            this.commonTexImage2D(target, level, null, width, height, null, format, type, hash, image, xoffset, yoffset, null, null); 
            return; // empty call
        }*/
        this.commonTexImage2D(target, level, null, width, height, null, format, type, hash, image, xoffset, yoffset, downscale, parsed_image); 
    }
}