'use strict';

import { AdsAdinplay } from "./ads-adinplay";
import { AdsTest } from "./ads-test";

export class Ads {

    constructor(ads_type) {
        this._ads_type = ads_type;
        this._loaded_placements = [];

        if(ads_type == "local") {
            this._ads = new AdsTest(this);
        }
        if(ads_type == "adsinplay") {
            this._ads = new AdsAdinplay(this);
        }

        mylogi("!!! INIT ADS WITH", ads_type);
    }

    load_ad(type, placemendId) {
        mylogi("!!! LOAD AD", type, placemendId);

        this._ads.load_ad(type, placemendId);
    }

    show_ad(type, placemendId) {
        if(window.Main.current_stream != null && document.fullscreenElement != null) {
            window.Main.current_stream.exitFullscreen();
        }
        
        this._ads.show_ad(type, placemendId);
    }

    ad_loaded(type, placemendId) {
        this._loaded_placements.push(placemendId);
        
        window.Main.sendToGame({
            "eventType": "ad",
            "type": type,
            "placement": placemendId,
            "ad_event": "loaded",
            "result": "ok"
        });
    }

    ad_load_failed(type, placemendId) {
        window.Main.sendToGame({
            "eventType": "ad",
            "type": type,
            "placement": placemendId,
            "ad_event": "loaded",
            "result": "noad"
        });
    }

    ad_shown(type, placemendId) {
        window.Main.sendToGame({
            "eventType": "ad",
            "type": type,
            "placement": placemendId,
            "ad_event": "shown",
            "result": "ok"
        });
    }

    ad_shown_failed(type, placemendId) {
        window.Main.sendToGame({
            "eventType": "ad",
            "type": type,
            "placement": placemendId,
            "ad_event": "shown",
            "result": "cancel"
        });
    }
}