'use strict';

export var Strings = {
    'migrate.link_ok': "Device is successfully linked, continue to play on",

    'overtime.header': "Your trial time expired 😔",
    'overtime.header.block': "Your trial time is going to expire soon",
    'overtime.header2': "We have a special membership offers for you",
    'overtime.smallheader2': "Continue to play now:",

    'overtime.slot1.caption': "ONE TIME",
    'overtime.slot1.period': "7 days",
    'overtime.slot1.give': ["Unlimited play time", "Instantly get 50 Crystals", "Daily bonus for 7 days", "Continue chatting"],

    'overtime.slot2.caption': "MONTHLY",
    'overtime.slot2.period': "while subscribed",
    'overtime.slot2.give': ["Unlimited play time", "Instantly get 100 Crystals", "Daily bonus each day", "Continue chatting", "Billed monthly", "Cancel anytime"],

    'signup.header': "Do you enjoy playing?",
    'signup.subheader': "Sign up for free:",

    'signup.option1': "Continue to play on any device",
    'signup.option2': "Save and retain your progress",
    'signup.option3': "Chat and claim bonuses",
    
    'overtime.options.header': "Sign up for free and get:",
    'overtime.options.option1': "30 free minutes today",
    'overtime.options.option2': "Save and retain your progress",

    'overtime.remind.header': "Come back tomorrow and get 30 minutes of free play",
    'overtime.remind.text': "Do you want us to remind you?",

    'toast.bonus.claimed': "🎁 Bonus claimed",
    'toast.signed.in': "Thank you for signing",

    'link.progress': "Scan the QR code to link your progress to another device to play anywhere",
}