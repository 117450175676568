import { Handles } from "./handles";

export class RenderFrameBuffers
{
    constructor(streamRender) {
        this._st = streamRender;

        this._handlesRender = new Handles("render");
        this._handlesFrame = new Handles("frame");

        this.generateFrameBuffers = this.generateFrameBuffers.bind(this);
        this.generateRenderBuffers = this.generateRenderBuffers.bind(this);
        this.renderbufferStorage = this.renderbufferStorage.bind(this);
        this.deleteRenderBuffers = this.deleteRenderBuffers.bind(this);
        this.deleteFrameBuffers = this.deleteFrameBuffers.bind(this);

        this.framebufferRenderbuffer = this.framebufferRenderbuffer.bind(this);
        this.framebufferTexture2D = this.framebufferTexture2D.bind(this);
        this.bindFramebuffer = this.bindFramebuffer.bind(this);
        this.bindRenderbuffer = this.bindRenderbuffer.bind(this);
    }

    functionReturn(cmd, ret, func_ret) {
    }

    resolveHandles(cmd, args) {
        if(('render_handle' in cmd['mapping']) && (cmd['mapping']['render_handle'].length > 0)) {
            args = this._handlesRender.resolveHandles(args, cmd['mapping']['render_handle']);
        }
        if(('frame_handle' in cmd['mapping']) && (cmd['mapping']['frame_handle'].length > 0)) {
            args = this._handlesFrame.resolveHandles(args, cmd['mapping']['frame_handle']);
        }
        return args;
    }

    getMapping() {
        return {
            'glGenFramebuffers': {
                'func': this.generateFrameBuffers
            },
            'glGenRenderbuffers': {
                'func': this.generateRenderBuffers
            },
            'glBindFramebuffer': {
                'func': this.bindFramebuffer,
                'frame_handle': [1]
            },
            'glBindRenderbuffer': {
                'func': this.bindRenderbuffer,
                'render_handle': [1]
            },
            'glFramebufferRenderbuffer': {
                'func': this.framebufferRenderbuffer,
                'render_handle': [3]
            },
            'glRenderbufferStorage': {
                'func': this.renderbufferStorage
            },
            'glDeleteRenderbuffers': {
                'func': this.deleteRenderBuffers
            },
            'glDeleteFramebuffers': {
                'func': this.deleteFrameBuffers
            },
            'glFramebufferTexture2D': {
                'func': this.framebufferTexture2D,
                'tex_handle': [3]
            }
        };
    }

    bindFramebuffer(target, framebuffer) {
        //console.log('FBO: bindFramebuffer', target, framebuffer);

        this._st._gl.bindFramebuffer(target, framebuffer);
    }

    bindRenderbuffer(target, renderbuffer) {
        //console.log('FBO: bindRenderbuffer', target, renderbuffer);

        // if render buffer is not a WebGLRenderbuffer object
        if(typeof renderbuffer !== 'object') {
            // create nre renderbuffer
            var idx = renderbuffer;
            renderbuffer = this._st._gl.createRenderbuffer();

            // add to handles
            this._handlesRender.addHandle(idx, renderbuffer);
        }

        this._st._gl.bindRenderbuffer(target, renderbuffer);
    }

    framebufferRenderbuffer(target, attachment, renderbuffertarget, renderbuffer) {
        //console.log('FBO: framebufferRenderbuffer', target, attachment, renderbuffertarget, renderbuffer);
        if(typeof renderbuffer !== 'object') {
            // create nre renderbuffer
            var idx = renderbuffer;
            renderbuffer = this._st._gl.createRenderbuffer();
            this._st._gl.bindRenderbuffer(this._st._gl.RENDERBUFFER, renderbuffer);

            // add to handles
            this._handlesRender.addHandle(idx, renderbuffer);
        }

        this._st._gl.framebufferRenderbuffer(target, attachment, renderbuffertarget, renderbuffer);
    }

    framebufferTexture2D(target, attachment, textarget, texture, level) {
        //console.log('FBO: framebufferTexture2D', target, attachment, textarget, texture, level);

        this._st._gl.framebufferTexture2D(target, attachment, textarget, texture, level);
    }

    generateFrameBuffers(amount, resulting_ids) {
        var decoded_ids = new DataView(resulting_ids.slice(0).buffer);
        for(var i=0; i<amount; i++) {
            var fbo = this._st._gl.createFramebuffer();

            //console.log('FBO: generateFrameBuffers', decoded_ids.getUint32(i*4, true), fbo);

            this._handlesFrame.addHandle(decoded_ids.getUint32(i*4, true), fbo);
        }
    }
    
    generateRenderBuffers(amount, resulting_ids) {
        var decoded_ids = new DataView(resulting_ids.slice(0).buffer);
        for(var i=0; i<amount; i++) {
            var fbo = this._st._gl.createRenderbuffer();

            //console.log('FBO: generateRenderBuffers', decoded_ids.getUint32(i*4, true), fbo);

            this._handlesRender.addHandle(decoded_ids.getUint32(i*4, true), fbo);
        }
    }
    
    deleteRenderBuffer(handle, id) {
        console.log('FBO: deleteRenderBuffer', handle, id);

        this._st._gl.deleteRenderbuffer(handle);
    } 
    
    deleteRenderBuffers(count, ids) {
        var decoded_ids = new DataView(ids.slice(0).buffer);
        for(var i=0; i<count; i++) {
            this.deleteRenderBuffer( this._handlesRender.getHandle(decoded_ids.getUint32(i*4, true)), decoded_ids.getUint32(i*4, true) );
        }
    }
    
    deleteFrameBuffer(handle, id) {
        //console.log('FBO: deleteFrameBuffer', handle, id);

        this._st._gl.deleteFramebuffer(handle);
    }
    
    deleteFrameBuffers(count, ids) {
        var decoded_ids = new DataView(ids.slice(0).buffer);
        for(var i=0; i<count; i++) {
            this.deleteFrameBuffer( this._handlesFrame.getHandle(decoded_ids.getUint32(i*4, true)), decoded_ids.getUint32(i*4, true) );
        }
    }
    
    renderbufferStorage(target, internalFormat, width, height) {
        //console.log('FBO: renderbufferStorage', target, internalFormat, width, height);

        if(internalFormat == 32856 || internalFormat == 32849) {
            internalFormat = this._st._gl.RGBA4;
        }
        this._st._gl.renderbufferStorage(target, internalFormat, width, height);
    }
}