export class Handles {
    constructor(name) {
        this._name = name;
        this._handles = {};
    }

    addHandle(handle_id, obj) {
        obj.handle_id = handle_id;
        
        this._handles[handle_id] = obj;
    }
    
    getHandle(handle_id) {
        if(handle_id in this._handles) {
            return this._handles[handle_id];
        }
        if(handle_id == 0) {
            return null;
        }
        return handle_id;
    }
    
    resolveHandles(args, handles) {
        for(var i=0; i<handles.length; i++) {
            args[handles[i]] = this.getHandle(args[handles[i]]);
        }
        return args;
    }
}

export class PositionHandles {
    constructor(name, shaders) {
        this._name = name;
        this._handles = {};
        this._shaders = shaders;
    }

    addHandle(handle_id, obj) {
        this._handles[handle_id] = obj;
    }
    
    getHandle(handle_id) {
        var hndl = handle_id + this._shaders._current_program.program_id*1000;
        if(hndl in this._handles)  {
            return this._handles[hndl];
        }
        if(handle_id == 0) {
            return null;
        }
        return handle_id;
    }
    
    resolveHandles(args, handles) {
        for(var i=0; i<handles.length; i++) {
            args[handles[i]] = this.getHandle(args[handles[i]]);
        }
        return args;
    }
}