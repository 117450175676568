'use strict';

export class PayWS {
    constructor(payments) {
        this._payments = payments;
    }

    async pay_now(pay_obj)
    {
        PayWS.showWebshop();
    }

    async confirmPurchase(invoice_id)
    {

    }

    static webshopURLS = {};

    static async showWebshop(fromclick = false) {
        var dev = parser.getDevice().type;
        var another_tab = false;
        if( dev == "mobile" || dev =="tablet" ) {
            another_tab = true;
        }

        // check if webshop url is already in the cache
        if(PayWS.webshopURLS[window.Main.getCurrentGame()] != null) {
            if(another_tab) {
                if(fromclick) {
                    window.open(PayWS.webshopURLS[window.Main.getCurrentGame()], '_blank');
                }else{
                    window.Main.open_rul(PayWS.webshopURLS[window.Main.getCurrentGame()]);
                }
            }else{
                window.ui.showWebshopPopup(PayWS.webshopURLS[window.Main.getCurrentGame()]);
            }
            return;
        }

        // query for file with ws url
        const wsconfig = await fetch(serverurl_pay+"api/pay/webshop/" + window.Main.getCurrentGame() + "/", {
            method: "GET"
        });

        // check http code
        if(wsconfig.status == 404) {
            alert("Webshop not configured");
            return;
        }

        const wsconfig_json = await wsconfig.json();

        var url = wsconfig_json["webshop"];

        if(another_tab) {
            if(fromclick) {
                window.open(url, '_blank');
            }else{
                window.Main.open_rul(url);
            }
        }else{
            window.ui.showWebshopPopup(url);
        }

        PayWS.webshopURLS[window.Main.getCurrentGame()] = url;
    }
} 