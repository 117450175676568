import { _LOG } from "../logger";
import { Handles } from "./handles";

export class RenderViewport
{
    constructor(streamRender) {
        this._st = streamRender;

        this.viewport = this.viewport.bind(this);
        this.scissor = this.scissor.bind(this);
    }

    functionReturn(cmd, ret, func_ret) {
    }

    resolveHandles(cmd, args) {
        return args;
    }

    getMapping() {
        return {
            // viewport stuff
            'glViewport': {
                'func': this.viewport
            },
            'glClearColor': {
                'func': this._st._gl.clearColor
            },
            'glColorMask': {
                'func': this._st._gl.colorMask
            },
            'glClear': {
                'func': this._st._gl.clear
            },
            'glScissor': {
                'func': this.scissor
            },
            'glClearDepth': {
                'func': this._st._gl.clearDepth
            },
            'glClearDepthf': {
                'func': this._st._gl.clearDepth
            },
            'glFrontFace': {
                'func': this._st._gl.frontFace
            },
            'glClearStencil': {
                'func': this._st._gl.clearStencil
            },

            // render states
            'glEnable': {
                'func': this._st._gl.enable
            },
            'glDisable': {
                'func': this._st._gl.disable
            },
            'glBlendFunc': {
                'func': this._st._gl.blendFunc
            },
            'glBlendFuncSeparate': {
                'func': this._st._gl.blendFuncSeparate
            },
            'glBlendEquationSeparate': {
                'func': this._st._gl.blendEquationSeparate
            },
            'glStencilFuncSeparate': {
                'func': this._st._gl.stencilFuncSeparate
            },
            'glStencilOpSeparate': {
                'func': this._st._gl.stencilOpSeparate
            },
            'glStencilMask': {
                'func': this._st._gl.stencilMask
            },
            'glCullFace': {
                'func': this._st._gl.cullFace
            },
            'glDepthFunc': {
                'func': this._st._gl.depthFunc
            },
            'glDepthMask': {
                'func': this._st._gl.depthMask
            },
            'glDepthRange': {
                'func': this._st._gl.depthRange
            },
            'glDepthRangef': {
                'func': this._st._gl.depthRange
            },
            'glPixelStorei': {
                'func': this._st._gl.pixelStorei
            },
            'customSync': {
                'func': this._st.customSync
            },
            'customPreloadEnd': {
                'func': this._st.customPreloadEnd
            },
            'customEnumMap': {
                'func': this._st._cmdReader.customEnumMap
            }
        };
    }

    viewport(x, y, width, height) {
        //$('#glcanvas').css({"height": height + "px", "width": width + "px"});
        
        if(this.resized == true) { // TODO: fix this - don't resize if framebuffer is bound
            this._st._gl.viewport(x, y, width, height);
            return;
        }

        _LOG(this, "Setting size of canvas: "+width + "x"+height, this._st._debug);

        this.resized = true;

        $('#glcanvas').attr("width", width);
        $('#glcanvas').attr("height", height);
        
        this._st._gl.viewport(x, y, width, height);
        this._st.resized();
    }

    scissor(x, y, width, height) {
        if(width == 110 && height == 31) {
            var realHeight = 89;
            this._st._gl.scissor(x, y - (realHeight - height), width, realHeight);
        }else{
            this._st._gl.scissor(x, y, width, height);
        }
    }
}