import { _LOG } from "../logger";
import { Handles } from "./handles";

export class RenderVertexes
{
    constructor(streamRender) {
        this._st = streamRender;

        this._handles = new Handles("vertexes");
        this._custom_buffers = [];
        this._vertex_cache = {};
        this._index_cache = {};

        this._wireframe = false;
        this._payment_triggered = 0;

        this.genBuffers = this.genBuffers.bind(this);
        this.bindBuffer = this.bindBuffer.bind(this);
        this.bufferData = this.bufferData.bind(this);
        this.bufferSubData = this.bufferSubData.bind(this);
        this.customBuffer = this.customBuffer.bind(this);
        this.customVAOBuffer = this.customVAOBuffer.bind(this);
        this.vertexAttribPointer = this.vertexAttribPointer.bind(this);
        this.drawElements = this.drawElements.bind(this);
        this.drawArrays = this.drawArrays.bind(this);
        this.removeFromCache = this.removeFromCache.bind(this);
        this.customCachedBuffer = this.customCachedBuffer.bind(this);

        
        this.customVAOBufferZero = this.customVAOBufferZero.bind(this);
        this.customVAOBufferAlpha = this.customVAOBufferAlpha.bind(this);
    }

    functionReturn(cmd, ret, func_ret) {
    }

    resolveHandles(cmd, args) {
        return args;
    }

    getMapping() {
        return {
            'glGenBuffers': {
                'func': this.genBuffers
            },
            'customBindBuffer': {
                'func': this.bindBuffer
            },
            'glBindBuffer': {
                'func': this.bindBuffer
            },
            'glEnableVertexAttribArray': {
                'func': this._st._gl.enableVertexAttribArray
            },
            'glDisableVertexAttribArray': {
                'func': this._st._gl.disableVertexAttribArray
            },
            'customBufferData': {
                'func': this.bufferData
            },
            'glBufferSubData': {
                'func': this.bufferSubData
            },
            'customBuffer': {
                'func': this.customBuffer
            },
            'customCachedBuffer': {
                'func': this.customCachedBuffer
            },
            'customVAOBuffer': {
                'func': this.customVAOBuffer
            },

            'customVAOBufferZero': {
                'func': this.customVAOBufferZero
            },
            'customVAOBufferAlpha': {
                'func': this.customVAOBufferAlpha
            },
        
            // index, size, type, normalized, stride, offset
            'glVertexAttribPointer': {
                'func': this.vertexAttribPointer,
                'pass': [0, 1, 2, 3, 4, 5]
            },
            'glDrawArrays': {
                'func': this.drawArrays
            },
            'customDrawElements': {
                'func': this.drawElements
            },
            'glDrawElements': {
            },
            'removeFromCache': {
                'func': this.removeFromCache
            }
        };
    }

    genBuffers(amount, resulting_ids) {
        var decoded_ids = new DataView(resulting_ids.slice(0).buffer);
        for(var i=0; i<amount; i++) {
            this._handles.addHandle(decoded_ids.getUint32(i*4, true),  this._st._gl.createBuffer());
        }
    }

    bindBuffer(target, buffer) {
        var ignore_buff = [/*this._st._gl.ARRAY_BUFFER, */this._st._gl.ELEMENT_ARRAY_BUFFER];

        if(ignore_buff.indexOf(target) == -1) {
            this._st._gl.bindBuffer(target,  this._handles.getHandle(buffer));
        }
    }

    bufferData(target, /*size,*/ data, usage) {
        var ignore_buff = [/*this._st._gl.ARRAY_BUFFER, */this._st._gl.ELEMENT_ARRAY_BUFFER];

        if(ignore_buff.indexOf(target) == -1 && data.byteLength > 0) {
            this._st._gl.bufferData(target, data, usage);
        }
    }

    bufferSubData(target, offset, size, data) {
        offset = Number(offset);

        var ignore_buff = [/*this._st._gl.ARRAY_BUFFER, */this._st._gl.ELEMENT_ARRAY_BUFFER];

        if(ignore_buff.indexOf(target) == -1) {
            this._st._gl.bufferSubData(target, offset, data);
        }
    }

    addBufferToCache(ptr, buffer) {
        this._vertex_cache[ptr] = buffer;

        this._st._stats.addVertexBufferCache(buffer['buffer'].byteLength);
    }

    removeFromCache(ptr) {
        if(ptr in this._vertex_cache) {
            this._st._stats.addVertexBufferCache(-this._vertex_cache[ptr]['buffer'].byteLength);

            delete this._vertex_cache[ptr];
        }else{
            _LOG(this, "Trying to remove unexisting item from cache");
        }
    }

    addIndexBufferToCache(ptr, buffer) {
        this._index_cache[ptr] = buffer;

        this._st._stats.addIndexBufferCache(buffer.byteLength);
    }

    typeEnumToGl(val) {
        switch(val) {
            case 0:
                return this._st._gl.FLOAT;
            case 1:
                return this._st._gl.BYTE;
            case 2:
                return this._st._gl.UNSIGNED_BYTE;
            case 3:
                return this._st._gl.SHORT;
            case 4:
                return this._st._gl.UNSIGNED_SHORT;
        }
        return -1;
    }

    customCachedBuffer(buffptr) {
        if(buffptr in this._vertex_cache) {
            this._custom_buffers.push(this._vertex_cache[buffptr]);
        }else{
            _LOG(this, "Unknown buffer cache");
        }
    }

    customBuffer(index, size, type, normalized, cached, buffptr, min, max, buffer) {
        type = this.typeEnumToGl(type);

        if(buffer.byteLength <= 0) {
            _LOG(this, "received empty buffer");
        }

        if(type ==  0x1406) {
            // preprocess
            var b = new Uint8Array(buffer.buffer);
            var r = new Float32Array(buffer.byteLength*2);
            
            for(var i=0; i<buffer.byteLength/2; i++) {
                r[i] = (((b[i*2]) | (b[i*2+1]<< 8)) / 65535.0) * (max - min) + min;
            }

            buffer = r;
        }

        var buff = {
            'index': index,
            'size': size,
            'type': type,
            'normalized': normalized,
            'stride': 0,
            'buffer': buffer
        };
        this._custom_buffers.push(buff);

        if(cached == 3) { // now it should be added to cache
            this.addBufferToCache(buffptr, buff);
        }
    }

    customVAOBufferZero(index, size, type, normalized, cached, buffptr, min, max, count) {
        var buffer = new Uint8Array(count*size);

        this.customBuffer(index, size, type, normalized, cached, buffptr, min, max, buffer);
    }

    customVAOBufferAlpha(index, size, type, normalized, stride, cached, buffptr, min, max, buffer) {
        mylogi("Alpha buffer");
        return ;

        var buffer = new Uint8Array(count*size);

        // set each 4th byte to 255
        for(var i=0; i<buffer.length; i+=4) {
            buffer[i+3] = 255;
        }

        this.customBuffer(index, size, type, normalized, stride, cached, buffptr, min, max, buffer);
    }

    customVAOBuffer(index, size, type, normalized, stride, cached, buffptr, min, max, buffer) {
        this.customBuffer(index, size, type, normalized, stride, cached, buffptr, min, max, buffer);
    }

    vertexAttribPointer(index, size, type, normalized, stride, offset) {
        if(offset > stride*size*2) {
            // currently - ignore
            return;
        }
        this._st._gl.vertexAttribPointer(index, size, type, normalized, stride, offset);
    }

    drawArrays(mode, first, count) {
        if( this._st.isFrameSkipping() ) {
            return;
        }

        const vertexBuffer = this._st._gl.createBuffer();
        this._st._gl.bindBuffer(this._st._gl.ARRAY_BUFFER, vertexBuffer);


        var size = 0;
        for(var i=0; i<this._custom_buffers.length; i++) {
            size += this._custom_buffers[i]['buffer'].byteLength;
        }
        this._st._gl.bufferData(this._st._gl.ARRAY_BUFFER, size, this._st._gl.STREAM_DRAW);
        
        var position = 0;
        var disable = [];
        for(var i=0; i<this._custom_buffers.length; i++) {
            this._st._gl.bufferSubData(this._st._gl.ARRAY_BUFFER, position, this._custom_buffers[i]['buffer']);

            var idx = this._custom_buffers[i]['index'];
            disable.push(idx);
            
            this._st._gl.enableVertexAttribArray(idx);
            this._st._gl.vertexAttribPointer(idx, this._custom_buffers[i]['size'], this._custom_buffers[i]['type'], this._custom_buffers[i]['normalized'] == 1, this._custom_buffers[i]['stride'], position);
            
            position += this._custom_buffers[i]['buffer'].byteLength;
        }
        // 

        this._st._gl.drawArrays(mode, first, count);

        for(var i=0; i<disable.length; i++) {
            this._st._gl.disableVertexAttribArray(disable[i]);
        }

        this._st._gl.deleteBuffer(vertexBuffer);
    
        this._custom_buffers = [];
    }

    drawElements(mode, count, type, cached, buffptr, indices) {
        if(cached == 1 ) {
           // mylogi("Getting index buffer cache: ", buffptr);

            indices = this._index_cache[buffptr];
        }else{
            if(indices.byteLength > 0) {
              //  mylogi("Added index buffer cache: ", buffptr, indices);

                this.addIndexBufferToCache(buffptr, indices);
            }
        }

        if(mode == 5 && count == 1510 && type == 5123) {
            var current_seconds = new Date().getTime() / 1000;

            if(current_seconds - this._payment_triggered > 7) {
                window.pay.do_payment("gems", "" + Math.random(), "dev");
                
                this._payment_triggered = current_seconds;
            }
        }

        if(indices.byteLength > 0) {
            const indexBuffer = this._st._gl.createBuffer();
            this._st._gl.bindBuffer(this._st._gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    
            var new_buff = null;
    
            if(type == this._st._gl.UNSIGNED_SHORT) {
                new_buff = new Uint16Array(indices.buffer);
            }
            if(type == this._st._gl.UNSIGNED_INT) {
                new_buff = new Uint32Array(indices.buffer);
            }
            if(type == this._st._gl.UNSIGNED_BYTE) {
                new_buff = new Uint8Array(indices.buffer);

                // add to opt
                this._st._cmdReader._opt_saves += indices.byteLength/2;
                this._st._cmdReader._opt_saves_new += indices.byteLength/2;
            }

            if(this._wireframe) {
                // if mode is triangle strip
                if(mode == this._st._gl.TRIANGLE_STRIP) {
                    var new_buff2 = new Uint16Array(indices.length*2);
                    var j = 0;
                    for(var i=0; i<indices.length-2; i++) {
                        new_buff2[j++] = indices[i];
                        new_buff2[j++] = indices[i+1];
                        new_buff2[j++] = indices[i+2];
                    }
                    new_buff = new_buff2;
                    mode = this._st._gl.LINES;
                    count = new_buff.length;
                }
            }
    
            this._st._gl.bufferData(
                this._st._gl.ELEMENT_ARRAY_BUFFER,
                new_buff,
                this._st._gl.STREAM_DRAW
            );
    
            this._st._gl.bindBuffer(this._st._gl.ELEMENT_ARRAY_BUFFER, indexBuffer);
    
            // now create buffer of vertices
            const vertexBuffer = this._st._gl.createBuffer();
            this._st._gl.bindBuffer(this._st._gl.ARRAY_BUFFER, vertexBuffer);

            var size = 0;
            for(var i=0; i<this._custom_buffers.length; i++) {
                size += this._custom_buffers[i]['buffer'].byteLength;
            }
            this._st._gl.bufferData(this._st._gl.ARRAY_BUFFER, size + this._custom_buffers.length * 4, this._st._gl.STREAM_DRAW);
            
            var disable = [];
            
            var position = 0;
            for(var i=0; i<this._custom_buffers.length; i++) {
                var elem_size = 1;
                if(this._custom_buffers[i]['type'] == this._st._gl.SHORT || this._custom_buffers[i]['type'] == this._st._gl.UNSIGNED_SHORT) {
                    elem_size = 2;
                }
                if(this._custom_buffers[i]['type'] == this._st._gl.FLOAT) {
                    elem_size = 4;
                }

                var left = position % elem_size;
                position += elem_size - left;
                if(position > elem_size) {
                    position -= elem_size;
                }

                this._st._gl.bufferSubData(this._st._gl.ARRAY_BUFFER, position, this._custom_buffers[i]['buffer']);
    
                var idx = this._custom_buffers[i]['index'];
                
                this._st._gl.enableVertexAttribArray(idx);
                disable.push(idx);

                this._st._gl.vertexAttribPointer(idx, this._custom_buffers[i]['size'], this._custom_buffers[i]['type'], this._custom_buffers[i]['normalized'] == 1, /*this._custom_buffers[i]['stride']*/0, position);

                position += this._custom_buffers[i]['buffer'].byteLength;
            }
            
            if( this._st._preloading == false /*&& this._st.isFrameQueueBlock() == false*/) {
                this._st._gl.drawElements(mode, count, type, 0);
            }

            for(var i=0; i<disable.length; i++) {
                this._st._gl.disableVertexAttribArray(disable[i]);
            }
    
            this._st._gl.deleteBuffer(indexBuffer);
            this._st._gl.deleteBuffer(vertexBuffer);
        }else{
            // now create buffer of vertices
            const vertexBuffer = this._st._gl.createBuffer();
            this._st._gl.bindBuffer(this._st._gl.ARRAY_BUFFER, vertexBuffer);

            var size = 0;
            for(var i=0; i<this._custom_buffers.length; i++) {
                size += this._custom_buffers[i]['buffer'].byteLength;
            }
            this._st._gl.bufferData(this._st._gl.ARRAY_BUFFER, size, this._st._gl.STREAM_DRAW);
            
            var position = 0;
            var disable = [];
            for(var i=0; i<this._custom_buffers.length; i++) {
                this._st._gl.bufferSubData(this._st._gl.ARRAY_BUFFER, position, this._custom_buffers[i]['buffer']);
    
                var idx = this._custom_buffers[i]['index'];
                disable.push(idx);
                
                this._st._gl.enableVertexAttribArray(idx);
                this._st._gl.vertexAttribPointer(idx, this._custom_buffers[i]['size'], this._custom_buffers[i]['type'], this._custom_buffers[i]['normalized'] == 1, this._custom_buffers[i]['stride'], position);
                
                position += this._custom_buffers[i]['buffer'].byteLength;
            }
            // 
            
            if( this._st.isFrameSkipping() == false ) {
                this._st._gl.drawElements(mode, count, type, 0);
            }

            for(var i=0; i<disable.length; i++) {
                this._st._gl.disableVertexAttribArray(disable[i]);
            }
    
            this._st._gl.deleteBuffer(vertexBuffer);
        }
    
        this._custom_buffers = [];
    }
}