import { _LOG } from './logger';
import { LZ4_decompress_fast_continue } from './lz4dec';

export class CommandReader {
    constructor(streamRender) {
        this._sr = streamRender;
        this._cm = streamRender._commandMapping;

        this._command_flushes = 0;

        this._current_cmd = 0;
        this._command_buff = [];

        this._frame_commands = [];
        this._frame_commands_prev = [];

        // stream reading
        this._previous_data = null;
        this._previous_offset = null;
        this._current_param = 0;
        this._current_command = null;

        this._current_type = null;
        this._current_type_buffer = null;
        this._current_type_length = null;
        this._current_type_size = null;

        this._compressed_stream_state = 0;
        this._compressed_stream_byte = 0;
        this._compressed_stream_pos = 0;
        this._compressed_stream_size = 0;
        this._compressed_stream_got = 0;
        this._compressed_stream_buff = null;

        this._enum_map = {};

        this.customEnumMap = this.customEnumMap.bind(this);

        this._opt_saves = 0;
        this._opt_saves_new = 0;

        // type sizes
        this._type_sizes = {
            'GLenum': {'size': 1, 'unsigned': true, 'int': true},
            'GLsync': {'size': 4, 'unsigned': true, 'int': true},
            'GLbitfield': {'size': 4, 'unsigned': true, 'int': true},
            'GLuint': {'size': 4, 'unsigned': true, 'int': true},
            'GLsizei': {'size': 4, 'unsigned': true, 'int': true},
            'GLint': {'size': 4, 'unsigned': false, 'int': true},
        
            'GLfloat': {'size': 4, 'unsigned': false, 'int': false},
            'GLclampf': {'size': 4, 'unsigned': false, 'int': false},
            'GLclampd': {'size': 4, 'unsigned': false, 'int': false},
            
            'GLdouble': {'size': 8, 'unsigned': false, 'int': false},
            
            'GLshort': {'size': 2, 'unsigned': false, 'int': true},
            'GLushort': {'size': 2, 'unsigned': true, 'int': true},
        
            'GLboolean': {'size': 1, 'unsigned': true, 'int': true},
            
            'GLsizeiptr': {'size': 8, 'unsigned': true, 'int': true},
            'GLintptr': {'size': 8, 'unsigned': true, 'int': true},
            'GLint64': {'size': 8, 'unsigned': true, 'int': true},
            'GLuint64': {'size': 8, 'unsigned': true, 'int': true},
        };
    }

    // queue processing
    getBufferSize() {
        return this._command_buff.length;
    }

    getFrameQueue() {
        return this._command_flushes;
    }

    canRenderFrame() {
        return this._command_flushes > 0;
    }

    getFrameCommands(cmd) {
        var ret = [];
        var cmd_now = this._current_cmd;
        var frame_ready = false;

        while(cmd_now < this._command_buff.length) {
            if(this._command_buff[cmd_now]['command'] == 'glCopyTexSubImage2D' /*|| this._command_buff[cmd_now]['command'] == 'glFlush'*/) {
                // end of frame
                frame_ready = true;
                //break;
            }

            if(this._command_buff[cmd_now]['command'] == cmd) {
                ret.push(this._command_buff[cmd_now]);
            }

            cmd_now++;
        }

        if(frame_ready) {
            return ret;
        }
        return [];
    }

    getFrameImageCommands() {
        var ret = [];
        var cmd_now = this._current_cmd;
        var frame_ready = false;

        while(cmd_now < this._command_buff.length) {
            if(this._command_buff[cmd_now]['command'] == 'glCopyTexSubImage2D' /*|| this._command_buff[cmd_now]['command'] == 'glFlush'*/) {
                // end of frame
                frame_ready = true;
                //break;
            }

            if( (this._command_buff[cmd_now]['command'] == 'customTexture' ||
                this._command_buff[cmd_now]['command'] == 'customSubTexture') && this._command_buff[cmd_now]['preloaded'] != true) {
                this._command_buff[cmd_now]['load_start'] = true;
                var cmd = this._command_buff[cmd_now];
                
                var args = cmd['params'];
                var image = args[10];

                if(image.byteLength == 0) {
                    cmd_now++;
                    continue;
                }

                ret.push(cmd);
            }

            cmd_now++;
        }

        if(frame_ready) {
            return ret;
        }
        return [];
    }

    readCommand() {
        var cmd = this._command_buff[this._current_cmd];
        if(cmd == null) {
            return null;
        }

        this._current_cmd = this._current_cmd + 1;

        if(this._current_cmd > 1000) {
            this._command_buff = this._command_buff.splice(this._current_cmd);
            this._current_cmd = 0;
        }

        return cmd;
    }

    frameRendered() {
        this._command_flushes = this._command_flushes - 1;
    }

    // reading of data
    incomingData(data, compressed) {
        if(compressed) {
            var bytes = new Uint8Array(data);
            this._compressed_stream_pos = 0;
            this._sr._stats.addIncomingCommandData(bytes.byteLength, 0);

            while(this._compressed_stream_pos < bytes.byteLength) {
                if( this._compressed_stream_state == 0 ) {
                    // reading size
                    if(bytes.length - this._compressed_stream_pos >= 2) {
                        this._compressed_stream_size = (bytes[this._compressed_stream_pos]) | (bytes[this._compressed_stream_pos + 1] << 8);

                        this._compressed_stream_pos += 2;
                        this._compressed_stream_state = 2;
                        this._compressed_stream_got = 0;

                        this._compressed_stream_buff = new Uint8Array(this._compressed_stream_size);
                    }else{
                        this._compressed_stream_state = 1; // read second byte from next package
                        this._compressed_stream_byte = bytes[this._compressed_stream_pos];
                        this._compressed_stream_pos += 1;
                        break;
                    }
                }
                if( this._compressed_stream_state == 1 ) {
                    this._compressed_stream_size = (this._compressed_stream_byte) | (bytes[this._compressed_stream_pos] << 8);
                    this._compressed_stream_pos += 1;
                    this._compressed_stream_state = 2;
                    this._compressed_stream_got = 0;
                    
                    this._compressed_stream_buff = new Uint8Array(this._compressed_stream_size);
                }
                if( this._compressed_stream_state == 2 ) {
                    // reading data into buffer
                    var can_read = Math.min(bytes.byteLength - this._compressed_stream_pos, this._compressed_stream_size - this._compressed_stream_got);

                    this._compressed_stream_buff.set(bytes.slice(this._compressed_stream_pos, this._compressed_stream_pos+can_read), this._compressed_stream_got);
                    this._compressed_stream_got += can_read;
                    this._compressed_stream_pos += can_read;

                    if(this._compressed_stream_got >= this._compressed_stream_size) {
                        var block_size = 65*1024;
                        
                        var uncompressed = LZ4_decompress_fast_continue( this._compressed_stream_buff, block_size, 0);
                        
                        this._compressed_stream_state = 0;
                        this._compressed_stream_size = 0;
                        this._compressed_stream_got = 0;

                        this._sr._stats.addIncomingCommandData(0, uncompressed.buffer.byteLength);

                        this.addToRenderBuf(uncompressed.buffer);
                    }
                }
            }            
        }else{
            this.addToRenderBuf(data);

            this._sr._stats.addIncomingCommandData(data.byteLength, data.byteLength);
        }
    }

    addToRenderBuf(data)
    {
        if(data.byteLength == 1) {
            return;
        }

        this.parseIncoming(new DataView(data));
    }

    isString(t) {
        return ['GL_string', 'const GLcharARB*', 'const GLchar*', 'GLchar*', 'GLcharARB*'].includes(t);
    }

    isBuffer(t) {
        return ['GL_string', 'const GLcharARB*', 'GLcharARB*', 'const GLchar*', 'GLchar*', 'GL_data'].includes(t);
    }

    parseIncoming(data)
    {
        var offset_buff = 0;
        var cmd_size = 1;
        
        while(offset_buff < data.byteLength) {
            if(this._current_command == null) {
                // read command id
                if(data.byteLength - offset_buff >= cmd_size) {
                    // can read command id
                    var cmd_id = null;

                    if(this._previous_data  != null) {
                        // read between buffers
                       /* var bytes = [];
                        var readed = 0;

                        for(var i=this._previous_offset; i<this._previous_data.byteLength; i++) {
                            bytes.push(this._previous_data.getUint8(i));
                            readed = readed + 1;
                        }

                        for(var i=0; i<cmd_size - readed; i++) {
                            bytes.push(data.getUint8(i));
                            offset_buff = offset_buff + 1;
                        }

                        var buff = new DataView(new Uint8Array(bytes).buffer);

                        cmd_id = buff.getUint8(0, true);
                        
                        this._previous_data  = null;
                        this._previous_offset = null;*/
                    }else{
                        // read from new buffer only
                        cmd_id = data.getUint8(offset_buff, true);
                        offset_buff += cmd_size;
                    }

                    try{
                        this._current_command = {
                            'size': cmd_size,
                            'command': this._cm._binary_mapping[cmd_id]['meta']['name'],
                            'meta': this._cm._binary_mapping[cmd_id]['meta'],
                            'mapping': this._cm._binary_mapping[cmd_id]['mapping'],
                            'params': []
                        };
                        this._opt_saves += 1;

                        if(this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customPreloadEnd') {
                            mylogi("PRELOAD COMMAND ARRIVED");
                        }

                        if(this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customBuffer' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customVAOBuffer' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customDrawElements' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'removeFromCache' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customCachedBuffer' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customCachedUniform' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customUniform2fv' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customUniform4fv' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customUniformMatrix4fv' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customUniform3fv' || 
                            this._cm._binary_mapping[cmd_id]['meta']['name'] == 'customUniform1fv'
                        ) {
                            this._opt_saves += 4; // hash 8b -> 4b
                        }

                        this._current_param = 0;
                    }catch(e) {
                        _LOG(this, "Error reading", cmd_id, this._command_buff.slice(this._command_buff.length-10));
                        throw e;
                    }
                }else{
                    // wait to read
                    break;
                }
            }else{
                if(this._current_type_buffer != null) {
                    // read buffer
                    var can_read = Math.min(this._current_type_length - this._current_type_size, data.byteLength - offset_buff);
                    this._current_type_buffer.set(new Uint8Array(data.buffer, offset_buff, can_read), this._current_type_size);

                    this._current_type_size += can_read;
                    offset_buff += can_read;

                    if(this._current_type_size >= this._current_type_length) {
                        // readed buffer, closing
                        if(this.isString(this._current_type)) {
                            this._current_command['params'].push(new TextDecoder().decode(this._current_type_buffer));
                        }else{
                            this._current_command['params'].push(this._current_type_buffer);
                        }

                        this._current_command['size'] += this._current_type_size;

                        this._current_param = this._current_param + 1;

                        this._current_type = null;
                        this._current_type_buffer = null;
                        this._current_type_size = null;
                        this._current_type_length = null;
                    }
                }else{
                    // read params
                    var total_params = this._current_command['meta']['params'].length;
                    if('ret' in this._current_command['meta']) {
                        total_params = total_params + 1;
                    }

                    if(this._current_param < total_params) {
                        var t = null;
                        if(this._current_param < this._current_command['meta']['params'].length) {
                            t = this._current_command['meta']['params'][this._current_param];
                        }else{
                            t = this._current_command['meta']['ret'];
                        }

                        if(this.isBuffer(t)) {
                            this._current_type = t;

                            t = 'GLuint';
                        }

                        var s = this._type_sizes[t]['size'];
                        var unsign = this._type_sizes[t]['unsigned'];
                        var int = this._type_sizes[t]['int'];
                        var ret = null;
                        
                        if(data.byteLength - offset_buff >= s) {
                            if(this._previous_data  != null) {
                                // read between buffers
                                var bytes = [];
                                var readed = 0;
                                for(var i=this._previous_offset; i<this._previous_data .byteLength; i++) {
                                    bytes.push(this._previous_data .getUint8(i));
                                    readed = readed + 1;
                                }

                                for(var i=0; i<s - readed; i++) {
                                    bytes.push(data.getUint8(i));
                                    offset_buff = offset_buff + 1;
                                }

                                this._previous_data  = null;
                                this._previous_offset = null;
                                
                                var buff = new DataView(new Uint8Array(bytes).buffer);

                                if(int) {
                                    if(s == 8) {
                                        ret = buff.getBigUint64(0, true);
                                    }
                                    if(s == 4 && unsign == true) {
                                        ret = buff.getUint32(0, true);
                                    }
                                    if(s == 4 && unsign == false) {
                                        ret = buff.getInt32(0, true);
                                    }
                                    if(s == 2 && unsign == true) {
                                        ret = buff.getUint16(0, true);
                                    }
                                    if(s == 2 && unsign == false) {
                                        ret = buff.getInt16(0, true);
                                    }
                                    if(s == 1 && unsign == true) {
                                        ret = buff.getUint8(0, true);
                                    }
                                }else{
                                    if(s == 4) {
                                        ret = buff.getFloat32(0, true);
                                    }
                                    if(s == 8) {
                                        ret = buff.getFloat64(0, true);
                                    }
                                }
                            }else{
                                // read from new buffer only
                                if(int) {
                                    if(s == 8) {
                                        ret = data.getBigUint64(offset_buff, true);
                                    }
                                    if(s == 4 && unsign == true) {
                                        ret = data.getUint32(offset_buff, true);
                                    }
                                    if(s == 4 && unsign == false) {
                                        ret = data.getInt32(offset_buff, true);
                                    }
                                    if(s == 2 && unsign == true) {
                                        ret = data.getUint16(offset_buff, true);
                                    }
                                    if(s == 2 && unsign == false) {
                                        ret = data.getInt16(offset_buff, true);
                                    }
                                    if(s == 1 && unsign == true) {
                                        ret = data.getUint8(offset_buff, true);
                                    }
                                }else{
                                    if(s == 4) {
                                        ret = data.getFloat32(offset_buff, true);
                                    }
                                    if(s == 8) {
                                        ret = data.getFloat64(offset_buff, true);
                                    }
                                }

                                offset_buff += s;
                            }

                            if(this._current_type == null) {
                                if(ret == null || ret == undefined) {
                                    throw new Error("Read broken");
                                }

                                this._current_command['size'] += s;

                                // if is enum
                                if( t == 'GLenum' ) {
                                    this._opt_saves += 3;

                                    //mylogi("Restoring original enum", this._current_command, this._current_command['meta']['id'], ret, this._enum_map);
                                    if(ret == 255) {
                                        mylogi("Broken enum arrived");
                                    }else{
                                        ret = this._enum_map[this._current_command['meta']['id']][ret];
                                    }
                                }
                                
                                if(this._current_param < this._current_command['meta']['params'].length) {
                                    this._current_command['params'].push(ret);
                                }else{
                                    this._current_command['ret'] = ret;
                                }
                                this._current_param = this._current_param + 1;

                                if(this._current_param >= total_params) {
                                    this.addNewCommand(this._current_command);
                                    this._current_command = null;
                                }
                            }else{
                                //mylogi("Reading buffer of size", ret);

                                this._current_type_buffer = new Uint8Array(ret);
                                this._current_type_length = ret;
                            }
                        }else{
                            // wait to read
                            break;
                        }
                    }else{
                        if(this._current_param >= total_params) {
                            this.addNewCommand(this._current_command);
                            this._current_command = null;
                        }
                    }
                }
            }
        }

        if(offset_buff < data.byteLength) {
            this._previous_data  = data;
            this._previous_offset = offset_buff;
        }else{
            this._previous_data  = null;
            this._previous_offset = null;
        }
    }

    customEnumMap(cmd_id, orig_enum, map_id) {
        //mylogi("New enum:", cmd_id, map_id, orig_enum);

        if(!(cmd_id in this._enum_map)) {
            this._enum_map[cmd_id] = {};
        }

        this._enum_map[cmd_id][map_id] = orig_enum;
    }

    addNewCommand(command) {
        if(command['command'] == 'customCommandMap' || command['command'] == 'customEnumMap') {
            // execute immediately, since next data will be custom command
            command['mapping']['func'].apply(this, command['params']);
        }else{
            this._command_buff.push(command);
            this._frame_commands.push(command);
            this._sr._stats.addCommandSize(command);

            if(command['command'] == 'glCopyTexSubImage2D' /*|| command['command'] == 'glFlush'*/) {
                this._frame_commands_prev = this._frame_commands.slice(0);
                this._frame_commands = [];

                this._command_flushes = this._command_flushes + 1;
            }
        }
    }
}