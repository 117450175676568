import { _LOG } from "./logger";

export class CommandMapping {
    constructor(streamRender) {
        this._sr = streamRender;

        this._command_mapping = {};   
        this._binary_mapping = null;
        this._onMappingReady = null;

        this._binary_types = {
            1: 'GLfloat',
            2: 'GLdouble',
            3: 'GLenum',
            4: 'GLuint',
            5: 'GLint',
            6: 'GLuint64',
            7: 'GLshort',
            8: 'GLushort',
            9: 'GLboolean',
            10: 'GLcharARB*',
            11: 'GLsync',
            12: 'GL_data'
        };

        this.customCommandMap = this.customCommandMap.bind(this)
    }

    registerMapping(module) {
        this._command_mapping = Object.assign(this._command_mapping, module.getMapping());
    }

    isMappingReady() {
        return this._binary_mapping != null;
    }

    onMappingReady(f) {
        if(this.isMappingReady()) {
            f();
        }

        this._onMappingReady = f;
    }

    customCommandMap(cmd_id, name, has_ret, params) {
        //console.log("customCommandMap", cmd_id, name, params);

        var meta = {
            "name": name,
            "id": cmd_id,
            "params": []
        };

        var read_param = (has_ret == 1)?params.length-1:params.length;
        //console.log("read_param", read_param, params.length, has_ret);

        for(var i=0; i<read_param; i++) {
            meta['params'].push( this._binary_types[params[i]] );
        }
        if(has_ret == 1) {
            meta['ret'] = this._binary_types[params[params.length-1]];
        }

        var added = false;
        for(var p in this._command_mapping) {
            if(p == name) {
                this._binary_mapping[cmd_id] = {
                    'mapping': this._command_mapping[p],
                    'meta': meta
                };
                added = true;
                break;
            }
        } 

        if(!added) {
            this._binary_mapping[cmd_id] = {
                'mapping': null,
                'meta': meta
            };
        }

        //console.log("New cmd mapping", this._binary_mapping[cmd_id]);
    }

    loadBinaryMapping(path) {
        this._binary_mapping = {
            0 : {
                "meta": {
                    "name": "customCommandMap",
                    "id": 0,
                    "params": [
                        "GLboolean",
                        "const GLchar*",
                        "GLboolean",
                        "GL_data"
                    ]
                },
                "mapping": {
                    'func': this.customCommandMap
                }
            }
        };

        /*$.get(path, (data) => {
            var mapped = 0;
            var unmapped = 0;

            this._binary_mapping = {};

            _LOG(this, "Loaded " + data.length + " bytes for mapping");
    
            for(var p in this._command_mapping) {
                for(var i=0; i<data.length; i++) {
                    if(data[i]['name'] == p) {
                        this._binary_mapping[data[i]['id']] = {
                            'mapping': this._command_mapping[p],
                            'meta': data[i]
                        };
                        mapped++;
                        break;
                    }
                }
            }

            // add all unknown commands - to parse properly
            for(var i=0; i<data.length; i++) {
                if( !(data[i]['id'] in this._binary_mapping) ) {
                    this._binary_mapping[data[i]['id']] = {'mapping': null, 'meta': data[i]};
                    unmapped++;
                }
            }

            _LOG(this, "Mapped commands:", mapped, "unmapped", unmapped);

            if( this._onMappingReady != null ) {
                this._onMappingReady();
            }
        });*/
    }
}