'use strict';

import { ConstructionOutlined } from "@mui/icons-material";

export class AdsAdinplay {
    constructor(ads) {
        this._ads = ads;
        this._rewarded_loaded = 0;
        this._can_reward = false;
        this._placement_now = "";

        aiptag.cmd.player.push(function() {
            aiptag.adplayer = new aipPlayer({
                AIP_REWARDEDNOTGRANTED: function (state)  {
                    //This event is fired when a rewarded ad is:
                    //timed out, empty, unsupported or closed by the user.
                    //don't grand the reward here
                    mylogi("Rewarded ad state: " + state); //state can be: timeout, empty, unsupported or closed.
                },
                AIP_REWARDEDGRANTED: function (event)  {
                    // This event is called whenever a reward is granted for a rewarded ad
                    if (event && "isTrusted" in event && event.isTrusted) {
                        mylogi("Reward Granted");

                        this._can_reward = true;
                    } else {
                        mylogi("Something went wrong don't grant the reward");
                    }
                },
                AIP_REWARDEDCOMPLETE: function (evt)  {
                    //evt can be: timeout, empty or closed
                    mylogi("Rewarded Ad Completed: " + evt);

                    if(this._can_reward) {
                        this._ads.ad_shown(0, "rewarded");
                    }else{
                        this._ads.ad_shown_failed(0, "rewarded");
                    }
                },
                AD_WIDTH: 960,
                AD_HEIGHT: 540,
                AD_DISPLAY: 'default', //default, fullscreen, fill, center, modal-center
                LOADING_TEXT: 'loading advertisement',
                PREROLL_ELEM: function(){return document.getElementById('preroll')},
                AIP_COMPLETE: function (state)  {
                    /*******************
                     ***** WARNING *****
                     *******************
                     Please do not remove the PREROLL_ELEM
                     from the page, it will be hidden automaticly.
                    */
                    
                     mylogi("Preroll Ad Completed: " + state);
                }
            });
        });

        this.preload_rewarded();
    }

    preload_rewarded() {
		if (typeof aiptag.adplayer !== 'undefined') {
			if(aipAPItag.rewardedSlotEventListener !== true) {
				aipAPItag.rewardedSlotEventListener = true;
				aiptag.events.addEventListener("rewardedSlotReady", function (e) {
					if(e.detail.isEmpty !== true) {
						mylogi("Rewarded Ad loaded");

                        this._rewarded_loaded = 1;
					} else {
						mylogi("Rewarded Ad not available");

                        this._rewarded_loaded = 2;
					}
				}, false);
			}

			aiptag.cmd.player.push(function() { aiptag.adplayer.startRewardedAd({preload: true, showLoading: false}); });
		} else {
			//Adlib didnt load this could be due to an adblocker, timeout etc.
			mylogi("Rewarded Ad Could not be loaded, handle your content here");

            this._rewarded_loaded = 3;
		}
    }	

    load_ad(type, placemendId) {
        if( type == 0 ){
            if(this._rewarded_loaded != 0) {
                // send callback of known state
                setTimeout(() => {
                    if(this._rewarded_loaded == 1) {
                        this._ads.ad_loaded(type, placemendId);
                    }else{
                        this._ads.ad_load_failed(type, placemendId);
                    }
                }, 500);
            }else{
                // wait for some time before callback
                setTimeout(() => {
                    if(this._rewarded_loaded == 1) {
                        this._ads.ad_loaded(type, placemendId);
                    }else{
                        this._ads.ad_load_failed(type, placemendId);
                    }
                }, 5000);
            }
        }else{
            setTimeout(() => {
                this._ads.ad_load_failed(type, placemendId);
            }, 500);
        }
    }

    show_ad(type, placemendId) {
        if(type == 0) {
            this._placement_now = placemendId;
            this._can_reward = false;
            //check if the adslib is loaded correctly or blocked by adblockers etc.
            if (typeof aiptag.adplayer !== 'undefined') {
                aiptag.cmd.player.push(function() { aiptag.adplayer.startRewardedAd(); });
            } else {
                //Adlib didnt load this could be due to an adblocker, timeout etc.
                //Please add your script here that starts the content, this usually is the same script as added in AIP_REWARDEDCOMPLETE.
                mylogi("Rewarded Ad Could not be loaded, load your content here");
            }
        }
    }
}