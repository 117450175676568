'use strict';

export class AdsTest {
    constructor(ads) {
        this._ads = ads;
    }

    load_ad(type, placemendId) {
        setTimeout(function() {
            this._ads.ad_loaded(type, placemendId);
        }.bind(this), 5000);
    }

    show_ad(type, placemendId) {
        if( type == 0 ) {
            window.ui.showRewardedTest(
                () => { this._ads.ad_shown_failed(type, placemendId); },
                () => { this._ads.ad_shown(type, placemendId); }
            );
        }
        if( type == 1 ) {
            window.ui.showInterstitialTest( () => { this._ads.ad_shown(type, placemendId) } );
        }
    }
}