import { _LOG } from "../logger";
import { load_image } from "./textures";

export class TextureStreaming
{
    constructor(streamRender) {
        this._st = streamRender;

        this._texture_debug = false;

        this._tex_waiting = [];
        this._incoming_textures = [];
        this._last_request = [];
        this._tex_cache = {};

        // reading helpers
        this._texture_read_state = 0;

        this._header_buffer_readed = 0;
        this._header_buffer = null;

        this._texture_buffer_readed = 0;
        this._texture_buffer = null;

        this._first_creation = true;
    }

    textureDeleted(texId) {
        for(var i=0; i<this._tex_waiting.length; i++) {
            if(this._tex_waiting[i]['texId'] == texId) {
                this._tex_waiting.splice(i, 1);
                i--;
            }
        }
    }

    newTextureData(data) {
        //console.log("New texture data arrived", data);

        this._st._stats.addIncomingTextureData(data.byteLength);

        var data_offset = 0;

        while(data_offset < data.byteLength) {
            if(this._texture_read_state == 0) {
                // start to read header
                var header_size = 32;
                var can_read = Math.min(header_size, data.byteLength - data_offset);
                
                this._header_buffer = new Uint8Array(header_size);

                var array_to_copy = new Uint8Array(data, data_offset, can_read);
                this._header_buffer.set( array_to_copy, 0 );

                data_offset += can_read;
                this._header_buffer_readed = can_read;

                if(this._header_buffer_readed == header_size) {
                    var buff = new DataView(this._header_buffer.buffer);
                    this._header_buffer = buff;

                    this._texture_buffer = new Uint8Array(buff.getUint32(20, true));
                    this._texture_buffer_readed = 0;

                    this._texture_read_state = 1; // read to buffer
                }else{
                    this._texture_read_state = 2; // continue to read header
                }
            }
            if(this._texture_read_state == 2) {
                var can_read = Math.min(header_size - this._header_buffer_readed, data.byteLength - data_offset);
                this._header_buffer.set( new Uint8Array(data, data_offset, can_read ), this._header_buffer_readed );
                data_offset += can_read;
                this._header_buffer_readed += can_read;

                if(this._header_buffer_readed == header_size) {
                    var buff = new DataView(header_buffer.buffer);
                    this._header_buffer = buff;

                    this._texture_buffer = new Uint8Array(buff.getUint32(28, true));
                    this._texture_buffer_readed = 0;

                    this._texture_read_state = 1; // read to buffer
                }else{
                    this._texture_read_state = 2; // continue to read header
                }
            }
            if(this._texture_read_state == 1) {
                // read texture to buffer
                var texture_size = this._header_buffer.getUint32(20, true);

                var key = "img_" + this._header_buffer.getUint32(4, true) + "_" +
                this._header_buffer.getUint32(8, true) + "_" +
                this._header_buffer.getUint32(12, true) + "_" +
                this._header_buffer.getUint32(16, true) + "_" +
                this._header_buffer.getBigUint64(24, true);

                //console.log("Waiting for texture of size", texture_size, key);

                var can_read = Math.min(texture_size - this._texture_buffer_readed, data.byteLength - data_offset);
                this._texture_buffer.set( new Uint8Array(data, data_offset, can_read ), this._texture_buffer_readed );
                data_offset += can_read;
                this._texture_buffer_readed += can_read;

                if(this._texture_buffer_readed == texture_size) {
                    var key = "img_" + this._header_buffer.getUint32(4, true) + "_" +
                                    this._header_buffer.getUint32(8, true) + "_" +
                                    this._header_buffer.getUint32(12, true) + "_" +
                                    this._header_buffer.getUint32(16, true) + "_" +
                                    this._header_buffer.getBigUint64(24, true);

                    mylogi("Arrived texture", key);
                    
                    /*var binary = '';
                    var len = this._texture_buffer.byteLength;
                    for (var i = 0; i < len; i++) {
                        binary += String.fromCharCode(this._texture_buffer[ i ] );
                    };

                    this.createIncomingTexture(key, window.btoa( binary ), "stream");*/

                    this._st._preloader.parseTexture(key, this._texture_buffer.buffer);

                    this._texture_read_state = 0;
                }
            }
        }
    }

    getMilliseconds() {
        var d = new Date();
        return d.getMilliseconds();
    }

    async createIncomingTexturesNow() {
        var tex_now = this._st._m_textures._active_texture;
        var target_now = this._st._m_textures._active_target;
        var was_change = false;

        var ms_start = this.getMilliseconds();
        var last_id = 0;

        for(var i=0; i<this._incoming_textures.length; i++) {
            if(this.getMilliseconds() - ms_start > 5 && this._first_creation == false) {
                break;
            }
            last_id = i;

            var key = this._incoming_textures[i]['key'];
            var img_data = this._incoming_textures[i]['img_data'];
            var source = this._incoming_textures[i]['source'];

            var found = false;
            var createdNow = true;
    
            while(createdNow) {
                createdNow = false;
                for(var k=0; k<this._tex_waiting.length; k++) {
                    if(this._tex_waiting[k]['key'] == key) {
                        if(this._texture_debug) {
                            _LOG(this, "Creating", key, "for", this._tex_waiting[k]['texId']);
                        }

                        found = true;
                        was_change = true;
                        
                        var img = null;
                        
                        try{
                            if(img_data != null && img_data.length > 0 && img_data != undefined) {
                                img = await load_image(img_data, this._tex_waiting[k]['width'], this._tex_waiting[k]['height'], this._tex_waiting[k]['key']);
                            }else{
                                //_LOG(this, "Broken incoming texture", img_data);
                                img = this._incoming_textures[i]['decoded_data'];
                            }
                        }catch(e) {
                            _LOG(this, "Cannot load texture from data", e);
                        }
                
                        if(img != null) {
                            this._st._stats.addUsedTexture(key);
                            this.addToCache(key, img, false);
                        }

                        if(img != null) {
                            this._st._m_textures.bindTexture(this._tex_waiting[k]['target'], this._tex_waiting[k]['texId']);// this._st._m_textures._handles.getHandle());
        
                            this._st._m_textures.glTexture(source, this._tex_waiting[k]['texId'], this._tex_waiting[k]['target'], this._tex_waiting[k]['level'],
                                                            this._tex_waiting[k]['internalformat'], this._tex_waiting[k]['border'], this._tex_waiting[k]['format'],
                                                            this._tex_waiting[k]['type'], img, this._tex_waiting[k]['xoffset'], this._tex_waiting[k]['yoffset']);
                        }else{
                            _LOG(this, "Error: null image");
                        }
                        this._tex_waiting.splice(k, 1);
                        createdNow = true;
                        break;
                    }
                }
            }
    
            if(!found) {
                this._st._stats.addUsedTexture(key);

                var img = this._incoming_textures[i]['decoded_data'];

                if(img != null && img != undefined) {
                    this.addToCache(key, img, false);
                }
            }
        }

        if(last_id > 0) {
            if(last_id >= this._incoming_textures.length - 1) {
                this._incoming_textures = [];
            }else{
                this._incoming_textures = this._incoming_textures.slice(last_id + 1);
            }
        }
        
        if(was_change) {
            this._st._m_textures.bindTexture(target_now, tex_now);
        }

        this._first_creation = false;

        // create images in cache

        this._st._m_textures.makeTextures();
    }

    createIncomingTexture(key, encodedData, source) {
        //_LOG(this, "Incoming texture", key);        
        this._incoming_textures.push({'key': key, 'img_data': encodedData, 'source': source});
    }

    createIncomingTextureDecoded(key, imageData, source) {
        //_LOG(this, "Incoming texture", key);        
        this._incoming_textures.push({'key': key, 'decoded_data': imageData, 'source': source});
        
        this.addToCache(key, imageData, false);
    }

    addWaitingTexture(texture) {
        this._tex_waiting.push(texture);

        if(this._texture_debug) {
            _LOG(this, "Waiting texture", texture['key']);
        }
    }

    isTexturePending(texId) { // optimize this
        for(var i=0; i<this._tex_waiting.length; i++) {
            if(this._tex_waiting[i]['texId'] == texId) {
                return true;
            }
        }
        return false;
    }

    // cache
    isInCache(key) {
        return key in this._tex_cache;
    }

    addToCache(key, data, temp) {
        if(key in this._tex_cache) {
            // remove previous one
            this._st._stats.addTextureCache(-1, this._tex_cache[key]['data']);
        }

        // add new
        this._tex_cache[key] = {'data': data, 'temp': temp};

        this._st._stats.addTextureCache(1, this._tex_cache[key]['data']);

        //_LOG(this, "Added texture to cache", key);
    }

    getFromCache(key) {
        return this._tex_cache[key];
    }

    isRequired(id) {
        var keys_to_load = [
        ];

        return keys_to_load.includes(id);
    }

    _arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    requestTexture(id) {
        for(var i=0; i<this._tex_waiting.length; i++) {
            if(this._tex_waiting[i]['texId'] == id) {
                this._st._preloader.waitingFor(this._tex_waiting[i]['key']);
                return;
            }
        }
    }

    sendUsedTextures(fps, frames_queue, binded_textures, only_new) {       
        this._last_request = [];

        // prepare sorted list to request textures by usage
        var texIds = Object.keys(binded_textures);
        var l = [];
        for(var i=0; i<texIds.length; i++) {
            var key = null;
            for(var k=0; k<this._tex_waiting.length; k++) {
                if(texIds[i] == this._tex_waiting[k]['texId']) {
                    key = this._tex_waiting[k]['key'];
                }
            }

            if(key != null && binded_textures[texIds[i]] > 7 || this.isRequired(key)) { // at least 10 calls
                l.push({'texId': texIds[i], 'usage': this.isRequired(key)?999:binded_textures[texIds[i]], 'key': key});
            }
        }

        l.sort((a, b) => (a.usage < b.usage) ? 1 : ((b.usage < a.usage) ? -1 : 0));

        //if(this._st._webRtc != null) {
        var texToSend = Math.min(l.length, 30);

        var header_size = 32;
        var packet_header = 4 + 4;
        var request_textures = new ArrayBuffer(texToSend*header_size + packet_header); 
        var buff = new DataView(request_textures);

        buff.setUint8(0, 1); // command number
        buff.setUint8(1, texToSend); // texture count
        buff.setUint8(2, Math.min(fps, 255)); // fps
        buff.setUint8(3, Math.min(frames_queue, 255)); // frames in queue
        
        buff.setUint32(4, this._st._frames_drawn, true); // frames in queue

        var new_last = [];

        var idx = 0;
        for(var i=0; (i<l.length) && (idx < texToSend); i++) {
            if(this._st._preloader.waitingFor(l[i]['key'])) {
                continue;
            }
            if(only_new && this._last_request.includes(l[i]['key'])) {
                continue;
            }

            new_last.push(l[i]['key']);

            var nums = l[i]['key'].split("_");

            buff.setUint32(packet_header + idx*header_size + 0, 0xB1BA, true);
            buff.setUint32(packet_header + idx*header_size + 4, nums[1], true);
            buff.setUint32(packet_header + idx*header_size + 8, nums[2], true);
            buff.setUint32(packet_header + idx*header_size + 12, nums[3], true);
            buff.setUint32(packet_header + idx*header_size + 16, nums[4], true);
            buff.setUint32(packet_header + idx*header_size + 20, 0, true);
            buff.setBigUint64(packet_header + idx*header_size + 24, nums[5], true);

            idx++;
        }

        this._st.sendTextureUsage(this._arrayBufferToBase64(request_textures) + "|");

        this._last_request = new_last;
    }
}