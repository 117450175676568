import { _LOG } from "./logger";
import { load_image } from "./renderers/textures";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export class Preloader
{
    constructor(renderer, image_prefix) {
        this._st = renderer;
        this._manifest = null;
        this._loadedImages = [];
        this._preloaded_second = false;
        this._image_prefix = image_prefix;
        
        this._neededImageSize = 0;
        this._image_loading = 0;
        this._load_timeout = -1;

        this._worker = new Worker('/imageloader.js');
        this._worker.addEventListener('message', (e) => {
            if(e.data['msg'] == "loaded") {
                var key = e.data['key'];
                var data = e.data['data'];

                this._st._textureStreaming.createIncomingTextureDecoded(key, data, "cdn");

                this._st.sendLoadedTexture(key);
            }
            if(e.data['msg'] == "parsed") {
                var key = e.data['key'];
                var data = e.data['data'];

                this._st._textureStreaming.createIncomingTextureDecoded(key, data, "stream");
            }
            if(e['data']['msg'] == "failed") {
                var key = e.data['key'];

                // remove from both manifest and loaded images
                this._manifest = this._manifest.filter((item) => {
                    return item['name'] != key + ".webp";
                });
                this._loadedImages = this._loadedImages.filter((item) => {
                    return item != key;
                });

                this._st._stats.resendTextureStats();

                mylogi("Failed image: ", key);
            }

            if( 'downloaded' in e.data ) {
                this._st._stats.addIncomingTextureData(e.data['downloaded']);
            }
            //mylogi(e.data['msg'], e.data['key'], 'downloaded' in e.data? (Math.round(e.data['downloaded']/1024.0*10)/10) + "kb" : "", e.data['queue']);

            var queue = e.data['queue'];

            if(queue == 5) {
                this._st._stats.resendTextureStats();
            }

            this._image_loading--;

            if(this._image_loading == 0 && this._preloaded_second == false) {
                // load second batch part
                if(this._load_timeout != -1) {
                    clearTimeout(this._load_timeout);
                    this._load_timeout = -1;
                }

                this._load_timeout = setTimeout(() => {
                    if(this._image_loading == 0) {
                        this.preloadBatch(3);
                        this._load_timeout = -1;
                    }
                }, 1000);
            }
        });
    }

    loadManifest(url) {
        $.get(url, (data) => {
            _LOG(this, "Images for preload:", data.length);

            this._manifest = data;

            this.startPreloading();
            this.preloadLastLoad();
        });
    }

    frameRendered() {

    }

    parseTexture(key, data) {
        this._loadedImages.push(key);

        this._worker.postMessage({"msg": "parse", key: key, data: data, premultiply: this._st._premultiply}, [data]);
        this._image_loading++;
    }

    waitingFor(key) {
        if(this._loadedImages.includes(key)) {
            return true;
        }
        if(this._manifest == null) {
            return false;
        }

        var fname = key + ".webp";

        for(let i=0; i<this._manifest.length; i++) {
            if(this._manifest[i]['name'] == fname) {
                this._worker.postMessage({"msg": "load", key: key, url: this._image_prefix + key + ".webp", premultiply: this._st._premultiply});

                this._image_loading++;

                this._loadedImages.push(key);
                return true;
            }
        }

        return false;
    }

    saveForNextLoad(keys) {
        window.localStorage.setItem("preload_keys", JSON.stringify(keys));
    }

    preloadLastLoad() {
        var keys = window.localStorage.getItem("preload_keys");

        if(keys == null) {
            mylogi("Load from list");
        } else {
            keys = JSON.parse(keys);

            for(let i=0; i<keys.length; i++) {
                this.waitingFor(keys[i]);
            }
        }
    }

    preloadBatch(max_images) {
        if(this._manifest == null) {
            return;
        }

        var load_start = 0;
        var i = 0;
        for(; i<this._manifest.length; i++) {
            if(this._manifest[i]['preload'] == 2) {
                var key = this._manifest[i]['name'].replace(".webp", "");

                if(this._loadedImages.includes(key)) {
                    continue;
                }
                this.waitingFor(key);

                load_start++;

                if(load_start >= max_images) {
                    break;
                }
            }
        }

        if( i >= this._manifest.length) {
            this._preloaded_second = true;
            _LOG(this, "Preloaded second batch");
        }
    }

    startPreloading(step=1) {
        if(this._manifest == null) {
            return;
        }

        for(let i=0; i<this._manifest.length; i++) {
            if(this._manifest[i]['preload'] == step) {
                this.waitingFor(this._manifest[i]['name'].replace(".webp", ""));
            }
        }
    }

    getRealSize(key) {
        for(let i=0; i<this._manifest.length; i++) {
            if(this._manifest[i]['name'] == key + ".webp") {
                if('width' in this._manifest[i] == false) {
                    return null;
                }

                return {'width': this._manifest[i]['width'], 'height': this._manifest[i]['height']};
            }
        }

        return null;
    }
}