'use strict';

export class PayXsolla {
    constructor(payments) {
        this._payments = payments;
        this._was_pay_callback = false;
        this._currentPayObj = null;

        this.confirmPurchase = this.confirmPurchase.bind(this);

        this.initCallbacks();
    }

    initCallbacks() {
        var thisXsolla = this;

        XPayStationWidget.on("status-done", function (event, data) {
            thisXsolla._was_pay_callback = true;

            var invoice_id = data.paymentInfo['invoice'];

            thisXsolla.confirmPurchase(invoice_id);
        });
        XPayStationWidget.on("status-troubled", function (event, data) {
        });
        XPayStationWidget.on("close", function (event, data) {
            if(thisXsolla._was_pay_callback == false) {
                setTimeout(() => {
                    if(thisXsolla._was_pay_callback == false) {
                        thisXsolla._payments.paymentCancel(thisXsolla._currentPayObj);
                    }
                }, 3000);
                

                var scroll_count = 5;

                let scrollAttempt = setInterval(() => {
                    if(scroll_count <= 0) {
                        clearInterval(scrollAttempt);
                    }
                    scroll_count--;
                    window.scrollTo(0, 0);
                }, 1000);
            }
        });
        XPayStationWidget.on("status-invoice", function (event, data) {
        });
        XPayStationWidget.on("status-delivering", function (event, data) {
        });
        XPayStationWidget.on("status", function (event, data) {
            thisXsolla._was_pay_callback = true;
        });
    }

    async pay_now(pay_obj)
    {
        try {
            this._was_pay_callback = false;
            this._currentPayObj = pay_obj;
            
            const response = await fetch(serverurl_pay+"api/xsolla/pay/"+window.profile.getProfileID()+"/"+pay_obj['game_id']+"/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(pay_obj),
            });

            const res_json = await response.json();

            this._last_desc = res_json['desc'];
            
            XPayStationWidget.init({
                access_token: res_json['token'],
                host: xsolla_sand?'sandbox-secure.xsolla.com':'secure.xsolla.com',
                iframeOnly: true,
                lightbox: {
                    zIndex: 10000
                }
            });
            XPayStationWidget.open();
        }catch(e) {
            this._payments.paymentError(e);

            throw e;
        }
    }

    async confirmPurchase(invoice_id) {
        const billing_response = await fetch(serverurl_pay+"api/pay/xsolla/"+invoice_id+"/", {
            method: "GET"
        });
        var billing_json = await billing_response.json();

        if(billing_json['status'] == "ok") {
            billing_json['purchase']['desc'] = this._last_desc;
            window.ui.showPaymentDone(billing_json['purchase'], billing_json['bonus']);
            
            this._payments.paymentDone(billing_json);

            setTimeout(() => {
                XPayStationWidget.close();
            }, 1000);

            var scroll_count = 5;

            let scrollAttempt = setInterval(() => {
                if(scroll_count <= 0) {
                    clearInterval(scrollAttempt);
                }
                scroll_count--;
                window.scrollTo(0, 0);
            }, 1000);
        }
    }
}