'use strict';

import { Strings } from "./string";

export class Login
{
    constructor() {
        this._login_enabled = isFlag('login');

        this._loggedIn = false;
    }

    findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        var items = location.search.substr(1).split("&");
        for (var index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    getName() {
        return window.profile.getItem("chatnick");
    }

    isLoggedIn() {
        if(!this._login_enabled) {
            return true;
        }

        var token = this.findGetParameter("token");
        if(token == null) {
            token = localStorage.getItem("jwt_token");
        }
        return token != null;
    }
}