import { _LOG } from "./logger";

var RING_BUFFER_SIZE = 1*1024*1024;

var buffer_before = new Uint8Array(RING_BUFFER_SIZE);
var buffer_before_pos = 0;

export function LZ4_decompress_fast_continue(buffer, maxBuffer, startPos) {
    var ret = new Uint8Array(maxBuffer);
    var ret_pos = 0;

    //console.log("Incoming buffer", buffer, buffer.byteLength, startPos);

    for(var pos=startPos; pos<buffer.byteLength;) { // first two bytes
        var token = buffer[pos++];

        //console.log("Token", token, token & 0xF, ((token >> 4) & 0xF));

        var matchlength = (token) & 0xF;
        var literals_length = ((token >> 4) & 0xF);

        if(literals_length == 15) {
            var more_literals;
            do{
                more_literals = buffer[pos++];
                literals_length += more_literals;
            }while(more_literals == 255);
        }

        for(; literals_length > 0; literals_length--) {
            ret[ret_pos++] = buffer[pos++];
        }
        if(pos == buffer.byteLength) {
            break;
        }

        var offset = (buffer[pos]) | ((buffer[pos + 1]) << 8);
        pos += 2;

        if(matchlength == 15) {
            var more_match;
            do{
                more_match = buffer[pos++];
                matchlength += more_match;
            }while(more_match == 255);
        }
        matchlength += 4; // minimal match

        if(offset == 0) {
            //console.log("Wrong 0 offset");
        }else{
            var start = ret_pos - offset;
            for(var k=0; k<matchlength; k++) {
                if(start + k < 0) {
                    // reading from previous buffers
                    var p = buffer_before_pos + (start + k);
                    if(p<0) {
                        p += RING_BUFFER_SIZE;
                    }
                    ret[ret_pos++] = buffer_before[ p ];

                    //console.log("Reading from perv buff", p, buffer_before_pos);
                }else{
                    ret[ret_pos++] = ret[start + k];
                }
            }
        }
    }

    //console.log("Uncompressed block, with size:", ret_pos, 'from', buffer.byteLength);
    //console.log(String.fromCharCode.apply(null, ret));

    var can_copy = Math.min(RING_BUFFER_SIZE - buffer_before_pos, ret_pos);
    buffer_before.set(ret.slice(0, can_copy), buffer_before_pos);
    buffer_before_pos += can_copy;

    if(can_copy < ret_pos) {
        // copy rest to beginning
        buffer_before.set(ret.slice(can_copy, ret_pos), 0);
        buffer_before_pos = ret_pos - can_copy;
    }

    //console.log("Copied to prev buff", can_copy, buffer_before, buffer_before_pos, ret, ret.slice(0, can_copy));

    return ret.slice(0, ret_pos);
}

export function test_lz4() {
    var oReq = new XMLHttpRequest();
    oReq.open("GET", "res/cmp_test", true);
    oReq.responseType = "arraybuffer";

    oReq.onload = (oEvent) => {
        LZ4_decompress_fast_continue(new Uint8Array(oReq.response, 0, 395).slice(0), 512); // first block
        LZ4_decompress_fast_continue(new Uint8Array(oReq.response, 395, 274).slice(0), 512); // second block
        LZ4_decompress_fast_continue(new Uint8Array(oReq.response, 669, 123).slice(0), 512); // third block

        // _LOG(this, this._cmdReader._command_buff.splice(0, 100));
    };

    oReq.send();
}