import { GameStream } from "./default/stream-sdk-19";
import { StreamRender } from "./stream/render";

export class ConnectorStream
{
    constructor(options) {
        this._onResize = this._onResize.bind(this);
        this._dimensions = null;
        this._first_frame = false;
        this._control_open = false;
        this._first_data = false;
        
        this._dcTexturePending = [];

        options.stream = {
            'video': false
        };

        this._ready_callback = options.callbacks.ready;
        options.callbacks.ready = () => {
        }

        options.dataChannels = {
            "game" : options.dataChannels["game"],
            "glstream": {
              callbacks: {
                close: () => {
                  mylogi('data channel is closed')
                },
                open: () => {
                  mylogi('data channel is open')
                },
                error: (err) => {
                  mylogi(`error: ${err}`)
                },
                message: (data) => {
                    //mylogi(data);

                    this._channelRender(data);
                }
              }
            },
            "gltextures": {
              callbacks: {
                close: () => {
                  mylogi('tex data channel is closed')
                },
                open: () => {
                  mylogi('tex data channel is open')
                },
                error: (err) => {
                  mylogi(`error: ${err}`)
                },
                message: (data) => {
                    this._channelTextures(data);
                }
              }
            },
            "glcontrol": {
              callbacks: {
                close: () => {
                  mylogi('control data channel is closed')
                },
                open: () => {
                  mylogi('control data channel is open');

                  this._control_open = true;
                  
                   this.sendQueue();
                },
                error: (err) => {
                  mylogi(`error: ${err}`)
                },
                message: (data) => {
                    this.firstDataReceived();
                }
              }
            },
        };

        this._options = options;

        this._stream = new GameStream(options);

        this._render = null;
        this._channelRender = null;
        this._channelTextures = null;
    }

    firstDataReceived() {
        this._first_data = true;

        this.sendQueue();
    }

    sendQueue() {
        if(this._control_open && this._first_data) {
            for(var i=0; i<this._dcTexturePending.length; i++){
                this.sendGLControlMessage(this._dcTexturePending[i]);      
            }
            this._dcTexturePending = [];

            var v = new Uint8Array([8, 1, 0]);
            this.sendGLControlMessage(this._arrayBufferToBase64(v) + "|");

            mylogi("!!!!!!!!!!!!! SENT READY !!!!!!!!!!!!!!!");
        }
    }

    _arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    getVideoID() {
        return this._stream._videoID;
    }

    getAudioID() {
        return this._stream._audioID;
    }

    getDimensions() {
        if(!this._first_frame) {
            return null;
        }
        return this._dimensions;
    }

    sendData(channelName, data) {
        this._stream.sendData(channelName, data);
    }

    setInputEnabled(enabled) {
        this._stream.setInputEnabled(enabled);
    }

    getInputEnabled() {
        return this._stream.getInputEnabled();
    }

    rotate(orientation, force=false, p2=false, p3=false) {
        mylogi("Rotating to", orientation);
        this._stream.rotate(orientation, force, this._first_frame, p3);
    }

    getCurrentOrientation() {
        return this._stream.getCurrentOrientation();
    }

    resized() {
        this._onResize();

        mylogi("Resized arrived");

        if(this._first_frame == false) {
            this._first_frame = true;
            this._stream._registerControls();
        }
    }

    preloaded() {
        setTimeout(() => {
            this._ready_callback();
        }, 10);
    }

    isplaystarted() {
        return this._first_frame;
    }

    _onResize() {
        this._stream._onResize();

        const container = document.getElementById(this._stream._containerID);
        if (container === null) return;
        
        const style = window.getComputedStyle(container, null);
        const getPadding = (direction) =>
            parseFloat(style.getPropertyValue("padding-" + direction) || "0");
        const containerHeight =
            container.clientHeight - getPadding("top") - getPadding("bottom");
        const containerWidth =
            container.clientWidth - getPadding("left") - getPadding("right");

        var canvas = document.getElementById('glcanvas');

        let videoHeight = canvas.height;
        let videoWidth = canvas.width;

        const resizePercentage = Math.min(
            containerHeight / videoHeight,
            containerWidth / videoWidth
        );

        const playerHeight = Math.round(videoHeight * resizePercentage);
        const playerWidth = Math.round(videoWidth * resizePercentage); 
    
        canvas.style.height = playerHeight.toString() + "px";
        canvas.style.width = playerWidth.toString() + "px";

        canvas.style.top = `${Math.round(
            container.clientHeight / 2 - playerHeight / 2
        )}px`;
        canvas.style.left = `${Math.round(
            container.clientWidth / 2 - playerWidth / 2
        )}px`;

        const offsetTop = Math.round(container.clientHeight / 2 - playerHeight / 2);
        const offsetLeft = Math.round(container.clientWidth / 2 - playerWidth / 2);

        this._dimensions = {
            videoHeight: videoHeight,
            videoWidth: videoWidth,
            scalePercentage: resizePercentage,
            playerHeight: playerHeight,
            playerWidth: playerWidth,
            playerOffsetLeft: offsetLeft,
            playerOffsetTop: offsetTop,
            containerWidth: container.clientWidth,
            containerHeight: container.clientHeight
          };

        if (this._stream._originalOrientation === null) {
            this._stream._originalOrientation = "landscape";
            this._stream._currentOrientation = this._stream._originalOrientation;
        }

        this._stream._dimensions = this._dimensions;
    }

    createGL() {
        var canvas = document.createElement('canvas');

        canvas.id = "glcanvas";
        canvas.width = 1;
        canvas.height = 1;
        canvas.style.position = "absolute";

        mylogi("Insetting gl at", "#" + this._options.targetElement);

        var container = document.getElementById(this._options.targetElement);
        container.appendChild(canvas);

        window.addEventListener('resize', () => {
            this._onResize();
        });
        this._onResize();
    }
    
    destroyGL() {
        var element = document.getElementById(this._options.targetElement);
        element.parentNode.removeChild(element);
    }

    disconnect() {
        this.destroyGL();

        this._stream.disconnect();
    }

    connect() {
        this.createGL();
        
        window.render = new StreamRender(prefix + "f/", prefix + "f/");

        window.render.connectToStream(this);
        window.render.startRendering();

        this._stream.connect();
        //this.setupInput();
    }

    _sendControlMessageQueue(action, args) {
        this._stream._webrtcManager._sendControlMessageQueue(action, args);
    }

    _onPointerEvent(evt) {
        this._stream._onPointerEvent(evt);
    }

    _onMouseWheel(evt) {
        this._stream._onMouseWheel(evt);
    }

    _sendInputEvent(evt, data) {
        this._stream._sendInputEvent(evt, data);
    }

    _requestFullscreen() {
        this._stream._requestFullscreen();
    }

    exitFullscreen() {
        this._stream.exitFullscreen();
    }

    // render callbacks for streams
    start(render, channelRender, channelTextures) {
        this._render = render;
        this._channelRender = channelRender;
        this._channelTextures = channelTextures;
    }

    sendGLControlMessage(textureUsage) {
        if(this._control_open && this._first_data) {
            this._stream.sendData("glcontrol", textureUsage);
        }else{
            this._dcTexturePending.push(textureUsage);
        }
    }

    setupInput() {
        const canvas = document.querySelector("#glcanvas");

        canvas.addEventListener("mousedown", (e) => {
            this._stream._sendInputEvent("mouse-button", {
                pressed: true,
                button: 2,
            });

            this._render.startFPSIncrease();
        });

        canvas.addEventListener("mousemove", (e) => {
            var rect = e.target.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var y = e.clientY - rect.top;
            
            this._stream._sendInputEvent("mouse-move", {
                x: x,
                y: y,
                rx: e.movementX,
                ry: e.movementY,
            });
        });

        canvas.addEventListener("mouseup", (e) => {
            this._stream._sendInputEvent("mouse-button", {
                pressed: false,
                button: 2,
            });

            this._render.stopFPSIncrease();
        });
    }

    startFPSIncrease() {
        this._render.startFPSIncrease();
    }

    stopFPSIncrease() {
        this._render.stopFPSIncrease();
    }    

    needsTimeout() {
        return false;
    }
}

// current_stream = stream;