import { _LOG } from "./logger";

export class StreamStats {
    constructor(streamRender) {
        this._sr = streamRender;

        this._dataBandwidth = {
            'compressed': 0,
            'uncompressed': 0,
            'current': 0,
            'thumbs': 0
        };

        this._textureBandwidth = {
            'total': 0,
            'current': 0
        }

        this._command_sizes = {};
        this._frames = 0;

        setInterval(() => {
            this.renderStats();
        }, 1000);

        this._is_unused = false;
        this._used_textures = {};
        this._used_textures_last = {};
        this._big_texture_load = [];

        this._all_textures = {};

        this._vertex_buffer_cache = 0;
        this._index_buffer_cache = 0;
        this._uniform_cache = 0;

        this._textures_cached = 0;
        this._textures_cached_size = 0;
        this._textures_gpu_size = 0;

        this._frame_start = 0;
        this._frame_times = [];
    }

    addImageGPU(image_size) {
        this._textures_gpu_size += image_size;
    }

    addTextureCache(amount, image) {
        this._textures_cached += amount;

        if(image == null) {
            return;
        }
        this._textures_cached_size += amount * (image.width * image.height * 4);
    }

    addVertexBufferCache(amount) {
        this._vertex_buffer_cache += amount;
    }

    addIndexBufferCache(amount) {
        this._index_buffer_cache += amount;
    }

    addUniformCache(amount) {
        this._uniform_cache += amount;
    }

    textureUsage(texId) {
        if(this._sr._textureStreaming.isTexturePending(texId)) {
            if(!(texId in this._used_textures)) {
                this._used_textures[texId] = 0;
            }
            this._is_unused = true;
            this._used_textures[texId]++;

            if(this._used_textures == 7) {
                // can be requested
                this.resendTextureStats();
            }

            this._sr._textureStreaming.requestTexture(texId);
        }

        this._all_textures[texId] = true;
    }

    frameRendered() {
        this._frames = this._frames + 1;
    }

    addIncomingTextureData(bytes) {
        this._textureBandwidth['total'] += bytes;
        this._textureBandwidth['current'] += bytes;
    }

    addIncomingThumbs(bytes) {
        this._dataBandwidth['thumbs'] += bytes;
    }

    addIncomingCommandData(compressed, uncompressed) {
        this._dataBandwidth['current'] += compressed;
        this._dataBandwidth['compressed'] += compressed;
        this._dataBandwidth['uncompressed'] += uncompressed;
    }

    addCommandSize(command) {
        if(!(command['command'] in this._command_sizes)) {
            this._command_sizes[command['command']] = 0;
        }
        this._command_sizes[command['command']] += command['size'];
    }

    addUsedTexture(key) {
        if(this._big_texture_load.includes(key) == false) {
            this._big_texture_load.push(key);
        }
    }

    sendUsedTextures(priority) {
        var json = {
            'priority': priority,
            'keys': []
        };

        for(var i=0; i<this._big_texture_load.length; i++) {
            json['keys'].push(this._big_texture_load[i]);
        }

        $.ajax("/preload", {
            data : JSON.stringify(json),
            contentType : 'application/json',
            type : 'POST',
        });
    }

    sendTextureStats() {
        this._sr._textureStreaming.sendUsedTextures(this._frames, this._sr._cmdReader.getFrameQueue(), this._used_textures, true);
    }

    resendTextureStats(){
        this._sr._textureStreaming.sendUsedTextures(this._frames, this._sr._cmdReader.getFrameQueue(), this._used_textures_last, true);
    }

    renderStats() {
        // bandwidth
        if(document.getElementById("stats") != null) {
            if(this._dataBandwidth['uncompressed'] > 0) {
                var mb = this._dataBandwidth['current'] / 1024.0 / 1024.0 * 8;
                this._dataBandwidth['current'] = 0;

                var mb_total = this._dataBandwidth['compressed'] / 1024.0 / 1024.0;

                document.getElementById("speed").innerHTML = Math.round(mb * 100) / 100 + "Mbps (total: "+
                                                (Math.round(mb_total*10)/10) + "MB; thumbs: "+(Math.round( (this._dataBandwidth['thumbs']/ 1024.0) *10)/10)+"KB; compression ratio: "+
                                                Math.round( (this._dataBandwidth['compressed'] / this._dataBandwidth['uncompressed']) * 100 )+"%)";
                
                document.getElementById("buffer_size").innerHTML = this._sr._cmdReader.getBufferSize();
            }

            mb = this._textureBandwidth['current'] / 1024.0 / 1024.0;
            mb_total = this._textureBandwidth['total'] / 1024.0 / 1024.0;
            document.getElementById("speed_tex").innerHTML = (Math.round(mb*10)/10) + "MB/s (total: "+(Math.round(mb_total*10)/10)+"MB)";
            this._textureBandwidth['current'] = 0;

            
            document.getElementById("cache_vertex").innerHTML = (Math.round((this._vertex_buffer_cache/1024/1024)*10)/10) + "MB";
            document.getElementById("cache_index").innerHTML = (Math.round((this._index_buffer_cache/1024/1024)*10)/10) + "MB";
            document.getElementById("cache_uniform").innerHTML = (Math.round((this._uniform_cache/1024/1024)*10)/10) + "MB";

            //document.getElementById("cache_textures").innerHTML = this._textures_cached + " ("+(Math.round((this._textures_cached_size/1024/1024)*10)/10)+"MB uncompressed)";
            //document.getElementById("cache_gpu").innerHTML = (Math.round((this._textures_gpu_size/1024/1024)*10)/10)+"MB";

            // frames
            document.getElementById("fps").innerHTML = this._frames;
        }

        // send texture needness and stats to streaming
        this.sendTextureStats();

        if(this._frames > 10) {
            // reset
            this._used_textures_last = this._used_textures;
            this._used_textures = {};
        }

        this._frames = 0;

        var keys = Object.keys(this._all_textures);
        this._sr._m_textures.saveForNextLoad(keys);

        this._all_textures = {};
    }

    frameStarted() {
        this._frame_start = new Date().getTime();
    }

    frameEnded() {
        var frame_time = new Date().getTime() - this._frame_start;

        this._frame_times.push(frame_time);

        if(this._frame_times.length > 100) {
            this._frame_times.shift();
        }
    }
}