import { CommandMapping } from './command-mapping';
import { CommandReader } from './command-reader';
import { _LOG } from './logger';
import { Preloader } from './preloader';
import { RenderFrameBuffers } from './renderers/framebuff';
import { RenderShaders } from './renderers/shaders';
import { RenderTextures } from './renderers/textures';
import { TextureStreaming } from './renderers/texturestreaming';
import { RenderUniforms } from './renderers/uniforms';
import { RenderVertexes } from './renderers/vertexes';
import { RenderViewport } from './renderers/viewport';
import { StreamStats } from './stats';
import { WebRTCconnection } from './connectors/webrtc';
import { RenderWebGL2 } from './renderers/webgl2';
import { ReplaceShadersExtension } from './extensions/replace_shaders';

function _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

export class StreamRender
{
    constructor(image_prefix, config_prefix) {
        _LOG(this, "Render created");

        this._webRtc = null;
        this._rendering = false;
        this._gl = null;
        this._stopOnError = false;
        this._connector = null;
        this._debug = false;
        this._debug_size = 0;
        this._data_received = false;
        this._preloading = true;
        this._frame_skipping = false;
        this._debug_enabled = window._is_local==true?true:false;
        this._total_cmd_runs = 0;

        // move to config

        // treasure
        this._webgl2 = false;
        this._alpha_options = false;
        this._premultiply = "none";

        // coc
    //    this._webgl2 = true;
     //   this._alpha_options = true;
     //   this._premultiply = "default";
        //

        this._last_fps_up = 1;

        if( getPlayTag() == "portrait" ) {
            image_prefix = image_prefix + "img_portrait/";
        }else{
            image_prefix = image_prefix + "img/";
        }

        this._image_prefix = image_prefix;
        this._config_prefix = config_prefix;

        this._stats = new StreamStats(this);
        this._commandMapping = new CommandMapping(this);
        this._cmdReader = new CommandReader(this);
        this._textureStreaming = new TextureStreaming(this);
        this._preloader = new Preloader(this, image_prefix);

        this._frame_commands = [];

        this._renderTimer = -1;
        this._all_break_cmds = [];
        this._render_modules = [];

        this._buffer_64 = "";
        this._buffer_tex_64 = "";

        this.throwOnGLError = this.throwOnGLError.bind(this);
        this.customSync = this.customSync.bind(this);
        this.customPreloadEnd = this.customPreloadEnd.bind(this);
        
        this._ignore_cmd = ['glGetString',
                            'glProgramParameteri',
                            'glGetShaderiv',
                            'glGetProgramiv',
                            'glGetIntegerv',
                            'glCheckFramebufferStatus',
                            'glGetBooleanv',
                            'glGetActiveUniform',
                            'glEGLImageTargetTexture2DOES',
                            'glGetAttribLocation',
                            'glGetError',
                            'glGetIntegeri_v',
                            'glIsVertexArray',
                            'glGetProgramBinary',
                            'glGetProgramInterfaceiv',
                            'glGetProgramResourceiv',
                            'glGetProgramResourceName',
                            'glFlush',
                        
                            // buffer map functions
                            'glMapBufferRange',
                            'glUnmapBuffer',
                            'glFlushMappedBufferRange',
                            'glDeleteBuffers'];
        
        this.init();
        this.initExtensions();
    }

    isDebug() {
        return this._debug_enabled;
    }

    isPreloading() {
        return this._preloading;
    }

    throwOnGLError(err, funcName, args) {
        //this._stopOnError = true;

       // _LOG(this, "Stopped on GL error");
    
        var argStr = "";
        var numArgs = args.length;
        for (var ii = 0; ii < numArgs; ++ii) {
            argStr += ((ii == 0) ? '' : ', ') +
            WebGLDebugUtils.glFunctionArgToString(funcName, numArgs, ii, args[ii]);
        }
        _LOG(this, "WebGL error "+ WebGLDebugUtils.glEnumToString(err) + " in "+ funcName + "(" + argStr + ")");
    
        //throw new Error("Stop on error");
    };

    initExtensions() {
        this._extensions = [];

        //this._extensions.push(new ReplaceShadersExtension(this));
    }

    init() {
        // prepare context / gl
        const canvas = document.querySelector("#glcanvas");

        /*canvas.addEventListener("mousedown", (e) => {
            var rect = e.target.getBoundingClientRect();
            var x = e.clientX - rect.left;
            var y = e.clientY - rect.top;
            
            if(this._connector != null) {
                this._connector.sendTap(x, y);

                this.setDynamicFPS(45);
                this._last_fps_up = new Date().getTime();
            }
        });*/

        if(this._webgl2) {
            this._gl = canvas.getContext("webgl2", {alpha: this._alpha_options});
        }else{
            this._gl = canvas.getContext("webgl", {alpha: this._alpha_options});
        }

        //this._gl = WebGLDebugUtils.makeDebugContext(this._gl);//, this.throwOnGLError);
        if (this._gl === null) {
            _LOG(this,
                "Unable to initialize WebGL. Your browser or machine may not support it."
            );
            return;
        }

        _LOG(this, "Created WebGL");

        this.initRenderModules();

        // load mappings
        this._commandMapping.loadBinaryMapping(this._config_prefix + "gl_mapping.json");

        // preload images
        if( getPlayTag() == "portrait" ) {
            this._preloader.loadManifest(this._config_prefix + "preload_portrait.json")
        }else{
            this._preloader.loadManifest(this._config_prefix + "preload.json")
        }
    }

    startFPSIncrease() {
        this.setDynamicFPS(45);
        this._last_fps_up = 0;
    }

    stopFPSIncrease() {
        this._last_fps_up = new Date().getTime();
    }

    initRenderModules() {
        this._m_shaders = new RenderShaders(this);
        this._render_modules.push(this._m_shaders);
        
        this._m_textures = new RenderTextures(this);
        this._render_modules.push(this._m_textures);

        this._m_framebuffs = new RenderFrameBuffers(this);
        this._render_modules.push(this._m_framebuffs);

        this._m_uniforms = new RenderUniforms(this);
        this._render_modules.push(this._m_uniforms);

        this._m_viewport = new RenderViewport(this);
        this._render_modules.push(this._m_viewport);

        this._m_vertexes = new RenderVertexes(this);
        this._render_modules.push(this._m_vertexes);

        if(this._webgl2) {
            this._m_webgl2 = new RenderWebGL2(this);
            this._render_modules.push(this._m_webgl2);
        }

        for(var i=0; i<this._render_modules.length; i++) {
            this._commandMapping.registerMapping(this._render_modules[i]);
        }
    }

    startRendering() {
        if(this._renderTimer == -1) {
            _LOG(this, "Started rendering");

            this._renderTimer = setInterval(() => {
                if(this._rendering == false && this._cmdReader.canRenderFrame()) {
                    this.renderFrame();
                }
            }, 1);
        }
    }

    stopRendering() {
        if(this._renderTimer != -1) {
            _LOG(this, "Stopped rendering");

            clearInterval(this._renderTimer);
            this._renderTimer = -1;
        }
    }

    loadStreamBuffer() {
        var oReq = new XMLHttpRequest();
        oReq.open("GET", "stream_buff.bin", true);
        oReq.responseType = "arraybuffer";

        oReq.onload = (oEvent) => {
            _LOG(this, "Readed buffer of size:", oReq.response.byteLength);
            this._cmdReader.incomingData(oReq.response, false);

           // _LOG(this, this._cmdReader._command_buff.splice(0, 100));
        };

        oReq.send();
    }

    connectToStream(connector, autoplay=true) {
        this._commandMapping.onMappingReady(() => {
            _LOG(this, "initing connector");
    
            this._connector = connector;
    
            this._connector.start(this,
                (data, uncompressed) => {
                    this._data_received = true;
                    
                    this._buffer_64 += data;
                    if(this._ping_interval != -1) {
                        clearInterval(this._ping_interval);
                        this._ping_interval = -1;
                    }
    
                    if(uncompressed == undefined || uncompressed == false || uncompressed == null) {
                        while(this._buffer_64.includes("|")) {
                            var sub = this._buffer_64.substring(0, this._buffer_64.indexOf("|"));
                            this._buffer_64 = this._buffer_64.substring(this._buffer_64.indexOf("|") + 1);
                            var b64 = _base64ToArrayBuffer(sub);
        
                            this._cmdReader.incomingData(b64, true);
                        }
                    }else{
                        this._cmdReader.incomingData(data, false);
                    }                    
                },
                (data) => {
                    this._buffer_tex_64 += data;
    
                    while(this._buffer_tex_64.includes("|")) {
                        var sub = this._buffer_tex_64.substring(0, this._buffer_tex_64.indexOf("|"));
                        this._buffer_tex_64 = this._buffer_tex_64.substring(this._buffer_tex_64.indexOf("|") + 1);
                        var b64 = _base64ToArrayBuffer(sub);
                        this._textureStreaming.newTextureData(b64);
                    }
                }
            );
    
            if(autoplay) {
                this.startRendering();

                this._ping_interval = setInterval(() => {
                    this.sendPingCommand();
                }, 200);
            }
        });
    }

    isFrameQueueBlock() {
        if(this._renderTimer == -1) {
            return false;
        }
        if(this._cmdReader.getFrameQueue() >= 5) {
            return true;
        }
        return false;
    }

    customPreloadEnd(enable, frame) {
        mylogi("Preload finished, resuming. Size:", Math.round(this._debug_size/1024.0/1024.0*10)/10, " MB", frame);

        this._preloading = false;

        this.sendUnlockCommand();

        this.preloaded();

        this._frames_drawn = frame;
    }

    customSync(enable, frame) {
        this._debug = enable == 1;
        mylogi("Sync state", enable, this._debug, frame);

        this._frames_drawn = frame;
    }

    async runCommand(cmd, debug=false) {
        this._total_cmd_runs = this._total_cmd_runs + 1;
        
        if(this._debug) {
           // mylogi("DEBUG", cmd);
            this._debug_size += cmd['size'];
        }

        //mylogi(cmd);
        //for(var i=0; i<cmd['params'].length; i++) {
        //    if(cmd['params'][i] == 46 || cmd['ret'] == 46) {
        //        mylogi("!!!!! DEBUG", cmd);
        //    }
        //}

        try{            
            if('mapping' in cmd && cmd['mapping'] != null && cmd['mapping'] != undefined) {
                var args = cmd['params'];

                /*
                if(('position' in cmd['mapping']) && (cmd['mapping']['position'].length > 0)) {
                    args = resolvePositions(args, cmd['mapping']['position']);
                }
                if(('tex_handle' in cmd['mapping']) && (cmd['mapping']['tex_handle'].length > 0)) {
                    args = resolveTextureHandles(args, cmd['mapping']['tex_handle']);
                }*/
                for(var i=0; i<this._render_modules.length; i++) {
                    args = this._render_modules[i].resolveHandles(cmd, args);
                }

                /*if(debug) {
                    mylogi(cmd, args);
                }*/

                if('pass' in cmd['mapping']) {
                    var new_args = [];
                    for(var i=0; i<cmd['mapping']['pass'].length; i++) {
                        new_args.push(args[ cmd['mapping']['pass'][i] ]);
                    }
                    args = new_args;
                }

                var ret = cmd['ret'];

                if('func' in cmd['mapping']) {
                    if('pass_ret' in cmd['mapping']) {
                        args.push(ret);
                    }
                    var func_ret = cmd['mapping']['func'].apply(this._gl, args);

                    for(var i=0; i<this._render_modules.length; i++) {
                        this._render_modules[i].functionReturn(cmd, ret, func_ret);
                    }
                    /*if( cmd['mapping']['ret_handle'] == true ) {
                        addHandle(ret, func_ret);
                    }
                    if( cmd['mapping']['ret_position'] == true ) {
                        addPosition(ret, func_ret);

                        if(all_uniforms.includes(cmd[2]) == false) {
                            all_uniforms.push(cmd[2]);
                            all_uniforms_idx.push(ret);
                        }
                    }*/
                }
                if(cmd['command'] == 'glCompileShader') {
                    var shader_now = args[0];
                    var compiled = this._gl.getShaderParameter(shader_now, this._gl.COMPILE_STATUS);
                    
                    if(!compiled) {
                        _LOG(this, "Compile issue: ", shader_now, cmd, this._gl.getShaderInfoLog(shader_now));
                    }
                }
                if(cmd['command'] == 'glLinkProgram') {
                    //mylogi("Current program (link): " + current_program.program_id);
                    var prg_now = args[0];

                    if (!this._gl.getProgramParameter(prg_now, this._gl.LINK_STATUS)) {
                        const info = this._gl.getProgramInfoLog(prg_now);
                        mylogi("Could not link program", info, prg_now);
                        //throw `Could not compile WebGL program. \n\n`;
                        prg_now.linked = false;
                    }else{
                        prg_now.linked = true;

                        this._m_shaders.useProgram(prg_now);

                        const numUniforms = this._gl.getProgramParameter(prg_now, this._gl.ACTIVE_UNIFORMS);
                        var unis = [];

                        for (let index = 0; index < numUniforms; index++) {
                            var uniform_info = this._gl.getActiveUniform(prg_now, index);
                            unis.push({'idx': index, 'name': uniform_info.name});
                        }
                        
                        _LOG(this, "Link " + prg_now.program_id + " ok. Found uniforms: " + numUniforms, unis);
                    }
                }
            }else{
                if(this._ignore_cmd.includes(cmd['command']) == false) {
                    if(cmd['command'] == 'glCopyTexSubImage2D' /*|| cmd['command'] == 'glFlush'*/) {

                        //this._gl.copyTexSubImage2D(cmd['params'][0], cmd['params'][1], cmd['params'][2], cmd['params'][3], cmd['params'][4], cmd['params'][5], cmd['params'][6], cmd['params'][7]);

                        if( this.isFrameSkipping() == false )  {
                            await this._gl.flush();
                            await this.frameRendered();

                            return true;
                        }
                        else{
                            this.frameRenderedSync();

                            return true;
                        }
                    }else{
                        _LOG(this, "Unknown command: ", cmd);
                    }
                }
            }
        }catch(e) {
            _LOG(this, "Issue on command run", e, cmd);
        }

        return false;
    }

    frameRenderedSync() {
        var was_block = this.isFrameQueueBlock();

        this._stats.frameRendered();
        this._cmdReader.frameRendered();
        this._preloader.frameRendered();

        if(was_block == true && this.isFrameQueueBlock() == false) {
            this._stats.sendTextureStats();
        }

        this._frames_drawn++;
    }

    async frameRendered() {

        await this._textureStreaming.createIncomingTexturesNow();

        if(this._last_fps_up > 0) {
            if( new Date().getTime() - this._last_fps_up > 3000 ) {
                this._last_fps_up = 0;

                this.setDynamicFPS(15);
            }
        }

        this.frameRenderedSync();
    }

    async preloadFrame() {
        var cmds = this._cmdReader.getFrameImageCommands();

        var p = [];
        for(var i=0; i<cmds.length; i++) {
            p.push(this._m_textures.preloadNextFrame(cmds[i]));
        }
        if(p.length > 0) {
            var results = await Promise.all(p);

            // sum of results
            var sum = results.reduce((a, b) => a + b, 0);

            mylogi("Preloaded", sum, "images. Skipped", p.length - sum);
        }

        for(var i=0; i<this._extensions.length; i++) {
            await this._extensions[i].preloadFrame();
        }
    }

    async debugCommand(frame, start, end) {
        this.stopRendering();

        this._gl.clearColor(0.5, 0.5, 0.0, 1.0);
        this._gl.clear(this._gl.COLOR_BUFFER_BIT);
        this._gl.scissor(0, 0, this._gl.canvas.width, this._gl.canvas.height);
        //this._gl.disable(this._gl.SCISSOR_TEST);

        var found = false;

        for(var i=0; i<frame.length; i++) {
            if(i >= start && i <= end) {
                mylogi(frame[i]);
                
                if(found == false) {
                    this._gl.clearColor(0.5, 0.5, 0.0, 1.0);
                    this._gl.clear(this._gl.COLOR_BUFFER_BIT);
                }

                found = true;
            }else{
                if(found) {
                    break;
                }
            }
            await this.runCommand(frame[i], true);
        }

        this._gl.colorMask(false, false, false, true);
        this._gl.clearColor(0.0, 0.0, 0.0, 1.0);
        this._gl.clear(this._gl.COLOR_BUFFER_BIT);
        this._gl.colorMask(true, true, true, true);

        await this._gl.flush();
    }

    isFrameSkipping() {
        return this._frame_skipping;
    }

    async renderFrame(command_count=-1, breakpoint=null) {
        this._stats.frameStarted();

        this._rendering = true;
        this._frame_commands = [];

        await this.preloadFrame();
        
        this._gl.colorMask(true, true, true, false);

        this._frame_skipping = this._preloading || this.isFrameQueueBlock();

        while(this._stopOnError == false) {
            var cmd = this._cmdReader.readCommand();
            if(cmd == null) {
                mylogi("no cmd", this._frame_skipping, this._cmdReader.getFrameQueue());
                break;
            }

            if(this._preloading == false && this._frame_skipping == true) {
                // last frame must be drawn
                if(this._cmdReader.getFrameQueue() <= 1) {
                    this._frame_skipping = false;
                }
            }

            if(cmd['command'] == breakpoint && breakpoint != null) {
                this._gl.clearColor(0.5, 0.5, 0.0, 1.0);
                this._gl.clear(this._gl.COLOR_BUFFER_BIT);
            }
            if(breakpoint != null) {
                this._all_break_cmds.push(cmd);
            }

            //mylogi(cmd);

            this._frame_commands.push(Object.assign({}, cmd));

            var res = await this.runCommand(cmd, command_count==1);

            if(cmd['command'] == breakpoint) {
                _LOG(this, "!!! Breakpoint triggered ", cmd, breakpoint);
                _LOG(this, "All commands", this._all_break_cmds);
                var size = 0;
                for(var i=0; i<this._all_break_cmds.length; i++) {
                    size += this._all_break_cmds[i]['size'];
                }
                _LOG(this, "Uncompressed size", size);
                this._all_break_cmds = [];

                break;
            }

            if(res == true && breakpoint == null) {
                if(this.isFrameSkipping() == false || this._cmdReader.canRenderFrame() == false) {
                    break;
                }
            }

            /*if(cmd['command'] == 'glCopyTexSubImage2D') {
                if( this._preloading == false && this.isFrameQueueBlock() == false || this._cmdReader.canRenderFrame() == false || res == true) {
                    break;
                }else{
                    //await this.preloadFrame();
                }
            }*/

            if(command_count > 0) {
                command_count = command_count - 1;
                if(command_count == 0) {
                    _LOG(this, cmd);
                    break;
                }
            }
        }

        this._stats.frameEnded();
        this._rendering = false;
    }

    sendTextureUsage(data) {
        if(this._connector != null) {
            this._connector.sendGLControlMessage(data);
        }
    }

    _arrayBufferToBase64( buffer ) {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    sendRecreateCommand() {
        var v = new Uint8Array([2, 0, 0]);
        this._connector.sendGLControlMessage(this._arrayBufferToBase64(v) + "|");
    }

    pause() {
        var v = new Uint8Array([3, 1, 0]);
        this._connector.sendGLControlMessage(this._arrayBufferToBase64(v) + "|");
    }

    unpause() {
        var v = new Uint8Array([3, 2, 0]);
        this._connector.sendGLControlMessage(this._arrayBufferToBase64(v) + "|");
    }

    setDynamicFPS(fps) {
        mylogi("Dynamic fps", fps);

        var v = new Uint8Array([4, fps, 0]);
        this._connector.sendGLControlMessage(this._arrayBufferToBase64(v) + "|");
    }

    sendPingCommand() {
        mylogi("Ping 2");

        var v = new Uint8Array([5, 0, 0]);
        this._connector.sendGLControlMessage(this._arrayBufferToBase64(v) + "|");
    }

    sendUnlockCommand() {
        mylogi("Unlock");

        var v = new Uint8Array([6, 1, 0]);
        this._connector.sendGLControlMessage(this._arrayBufferToBase64(v) + "|");
    }

    sendLoadedTexture(key) {
        if(this._connector == null) {
            _LOG(this, "Connector not ready");
            return;
        }

        // if 18253049504853584251 inside key
        if(key.indexOf("18253049504853584251") != -1) {
            mylogi("Loaded 18253049504853584251");
        }

        var header_size = 24;
        var packet_header = 1;
        var loaded_texture = new ArrayBuffer(header_size + packet_header); 
        var buff = new DataView(loaded_texture);

        buff.setUint8(0, 7); // command number
    
        var nums = key.split("_");

        // "img_" + width + "_" + height + "_" + format + "_" + type + "_" + hash;
        buff.setUint32(packet_header + 0, nums[1], true); // width
        buff.setUint32(packet_header + 4, nums[2], true); // height
        buff.setUint32(packet_header + 8, nums[3], true); // format
        buff.setUint32(packet_header + 12, nums[4], true); // type
        buff.setBigUint64(packet_header + 16, nums[5], true); // hash

        this._connector.sendGLControlMessage(this._arrayBufferToBase64(loaded_texture) + "|");
    }

    resized() {
        this._connector.resized();
    }

    preloaded() {
        this._connector.preloaded();
    }
}

/////////////////////////////////////////////////////////////////////////